import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from './layout/layout';
import AppRoutes from './routes/routes';
import { MsalProvider } from '@azure/msal-react';
import { getAllowedRoutes } from './utils/sidebar';
import { useSelector } from 'react-redux';
import { RootState } from './store/reducers/rootReducer';
import removeLocalStorageData from './utils/removeLocalStorageData';

const App = ({ msalInstance }: { msalInstance: any }) => {
  const [allowedRoutes, setAllowedRoutes] = useState([]);
  const userDetails = useSelector((state: RootState) => state.authenticationReducer.userDetails);
  useEffect(() => {
    const restrictedRoutes = getAllowedRoutes(AppRoutes);
    setAllowedRoutes(restrictedRoutes);
    if(Object.keys(userDetails).length === 0) {
      removeLocalStorageData();
    }
  }, [userDetails]);
  return (
    <MsalProvider instance={msalInstance}>
      <Layout>
        <Routes>
          {allowedRoutes.map((route: any, index: number) => {
            const { element, ...rest } = route;
            return <Route key={index} {...rest} element={element} />
          })}
        </Routes>
      </Layout>
    </MsalProvider>
  );
}

export default App;
