import React, { Component, useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { IdTokenData } from '../../DataDisplay';
import SearchComponent from '../../reusableComponents/searchComponent';
import { AddIcon, AdminIcon, CrownIcon, DeleteIcon, DownArrowIcon, EditIcon, NextArrowIcon, RemoveIcon, SaveIcon } from '../../reusableComponents/svgIcons';
import UserData from '../../../config/userDetail.json';
import { Autocomplete, Breadcrumbs, TextField } from '@mui/material';
import { getAllUserDetails } from '../../../store/actions/userManagement/userManagementAction';
import { useDispatch } from 'react-redux';
import { UserDetailsType } from '../../../store/actions/authentication/interfaces';
import CreateUserPopup from './createUserPopup';
function userViewIndex() {

  const dispatch = useDispatch();
  const { accounts } = useMsal();
  // const [searchedAssessment, setSearchedAssessment] = useState([] as any[]);
  const [user, setUser] = useState(true);
  const [usersList, setUsersList] = useState([]);
  const [editUser, setEditUser] = useState({
    firstName: "",
    lastName: "",
    email: ""
  });
  const [searchedAssessment, setSearchedAssessment] = useState([] as any[]);

  // Create user popup state
  const [isCreateUserPopupEnabled, setIsCreateUserPopupEnabled] = useState<boolean>(false);

  // const initialGet = async () => {
  //     console.log("in");
  //   const usersInfo = await dispatch(getAllUserDetails());
  //   if (usersInfo.length > 0) {
  //     setUsersList(usersInfo as UserDetailsType[])
  //   }
  //   //console.log(usersInfo);
  // }
  // useEffect( () => {
  //     initialGet();
  // },[]);


  const searchHandler = (value: string) => {
    const searchedData: any[] = search("", value);
    setSearchedAssessment(searchedData);
    console.log(value);
  }
  /* here we create a function 
//     we filter the items
// use array property .some() to return an item even if other requirements didn't match
*/
  const search = (items: any, q: string) => {
    const searchParam = ["firstName", "lastName"];
    const searchedData: any[] = [];
    items.map((data: any) => {
      const matchedData = data.assessment.filter((item: any) => {
        return searchParam.some((newItem) => {
          return (
            item[newItem]
              .toString()
              .toLowerCase()
              .indexOf(q.toLowerCase()) > -1
          );
        });
      });
      matchedData.length > 0 && searchedData.push(data);
    })
    return searchedData;
  }
  //function to pass user detail for edit page
  const editFunction = (userData: { firstName: string; lastName: string; email: string }) => {
    setUser(false);
    setEditUser(userData);
  }
  // handle add connection popup
  const handleCreateUserPopup = () => {
    setIsCreateUserPopupEnabled(true);
  };
  return (
    <>
      {/* User Management Screen... */}
      {user == true && (
        <section className='projectListSection pb-4'>
          <div className='assessmentTopSticky d-flex flex-wrap justify-content-between align-items-center px-3 pt-4 pb-2'>
            <SearchComponent searchHandler={searchHandler} />
            <div className="customButton noBg saveButton">
              <button onClick={handleCreateUserPopup}><div><AddIcon /></div><div className='ps-3'>Create User</div></button>
            </div>
          </div>
          <div className="projectList px-3 pt-2">
            <div className="d-flex flex-wrap justify-content-between">
              <div className="d-flex flex-wrap justify-content-between ps-5">
                <p className='p-3 ms-5 px-5'><b>First Name</b></p>
                <p className='p-3 px-5'><b>Last Name</b></p>
                <p className='p-3 px-5'><b>Email id</b></p>
              </div>
              <div className='pe-5'>
                <p className='p-3 me-5'><b>Actions</b></p>
              </div>
            </div>
            {UserData.map((userData, index) => (
              <div className='projectListChild p-4 mt-3' key={index}>
                <div className='d-flex w-100'>
                  <div><div className='PLBox'>{userData.firstName.charAt(0)}{userData.lastName.charAt(0)}</div></div>
                  <div className='PLDetails flex-grow-1 px-3  align-self-center'>
                    <span className='p-text ps-5'>{userData.firstName}</span>
                    <span className='p-text ps-5 ms-4'>{userData.lastName}</span>
                    <span className='p-text ps-5 ms-4'>{userData.email}</span>
                  </div>
                  <div className='PLActions  align-self-center'>
                    <div className="d-flex">
                      <div className='pe-4'><button onClick={() => editFunction(userData)}><EditIcon /></button></div>
                      <div className='ps-4 lineLeft'>
                        <button className='disabled' disabled><DeleteIcon /></button>
                      </div>
                    </div>
                  </div>
                  <div className='PLGo align-self-center ps-5 pe-4'>
                    <button><NextArrowIcon /></button>
                  </div>
                  {/* <div className='PLGo align-self-center ps-5 pe-4'>
                            <button onClick={() => setExpand(false)}><DownArrowIcon /></button>
                        </div> */}
                </div>
              </div>
            ))}
          </div>
          {isCreateUserPopupEnabled && (
            <CreateUserPopup
              setIsCreateUserPopupEnabled={setIsCreateUserPopupEnabled}
            />
          )}
        </section>
      )}

      {/* Edit User screen... */}
      {user == false && (
        <section className='projectViewSection px-3'>
          <div className='d-flex align-items-center mt-3 mb-4'>
            <div className='assessmentWPBreadcrumbs flex-grow-1'>
              <Breadcrumbs separator={<NextArrowIcon />} aria-label="breadcrumb">
                <button onClick={() => setUser(true)}>User Management</button>
                <div>Edit User</div>
              </Breadcrumbs>
            </div>
            <div className="customButton noBg m-0">
              <button><div><SaveIcon /></div><div className='ps-3'>Save</div></button>
            </div>
          </div>
          <div className='m-0 m-sm-3 p-0 p-sm-4 shadow-lg bg-white PVDetails p-4'>
            <div className='d-flex flex-nowrap flex-md-nowrap'>
              <div className='px-3'><div className='PVBox'>{editUser.firstName.charAt(0)}{editUser?.lastName.charAt(0)}</div></div>
              <div className='flex-grow-1 pVDetailcon px-3'>
                <div>
                  <div className='d-flex flex-wrap justify-content-between'>
                    <div className='px-5'>
                      <p><b>First Name</b></p>
                      <p>{editUser.firstName}</p>
                    </div>
                    <div className='px-5'>
                      <p><b>Last Name</b></p>
                      <p>{editUser.lastName}</p>
                    </div>
                    <div className='px-5'>
                      <p><b>Email</b></p>
                      <p>{editUser.email}</p>
                    </div>
                  </div>
                  <div className='PVUsers mt-3'>
                    <div className='d-flex flex-wrap'>
                      <div className='col-12 col-md-4 col-sm-12 pe-0 pe-md-3 ps-5 mt-5'>
                        <div className='selectFormGroup'>
                          <label htmlFor='reportType'><b>Select Role</b></label>
                          <Autocomplete
                            style={{ width: "100%" }}
                            popupIcon={<DownArrowIcon />}
                            id="reportType"
                            options={[]}
                            value={[]}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} placeholder="Admin" />}
                            onChange={(e, newValue) => console.log(newValue)}
                          />
                        </div>
                      </div>
                      <div className=' col-12 col-md-7 col-sm-12 pe-0 pe-md-3 ps-5 mt-5'>
                        <label htmlFor='reportType'><b>Select Projects</b></label>
                        <div className="d-flex  align-items-center flex-wrap PVUsersRow justify-content-center">

                          <div className='PVUsersChild ps-1 py-1 mb-2 me-2 pe-5 mt-2'>
                            <div className="d-flex align-items-center">
                              <div><span>PN</span></div>
                              <div className='PVUserName ps-2'>ProjectName 1</div>
                              <button className='PVRemoveUser'><RemoveIcon /></button>
                            </div>
                          </div>
                          <div className='PVUsersChild ps-1 py-1 mb-2 me-2 pe-5 mt-2'>
                            <div className="d-flex align-items-center">
                              <div><span>PN</span></div>
                              <div className='PVUserName ps-2'>ProjectName 2</div>
                              <button className='PVRemoveUser'><RemoveIcon /></button>
                            </div>
                          </div>
                          <button className='PVUsersChild addAdminButton ps-1 py-1 pe-4 mb-2 me-2 mt-2'>
                            <div className="d-flex align-items-center">
                              <div><span><AddIcon /></span></div>
                              <div className='PVUserName ps-2'>
                                <Autocomplete
                                  sx={{
                                    display: 'inline-block',
                                    '& input': {
                                      width: 200,
                                      bgcolor: 'background.paper',
                                      color: (theme) =>
                                        theme.palette.getContrastText(theme.palette.background.paper),
                                    },
                                  }}
                                  id="custom-input-demo"
                                  options={[]}
                                  renderInput={(params) => (
                                    <div ref={params.InputProps.ref}>
                                      <input type="text" {...params.inputProps} placeholder='Add' />
                                    </div>
                                  )}
                                  onChange={(e, newValue) => console.log(newValue)}
                                />
                              </div>
                              {/* <div className='PVUserName ps-2'>Add Admin</div> */}
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>


                    <div className='ps-5 mt-5'>
                      <h5 className='mb-3'>Access Persmission</h5>
                      <p className='mb-3'>User Creation</p>
                      <p className='mb-3'>Assessment &amp; Project Creation</p>
                      <p className='mb-3'>View Reports</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </section>
      )}
    </>
  )
}

export default userViewIndex