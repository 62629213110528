export const releaseNotes = [
  {
    date: "10 Aug 2022",
    label: "Version 0.1",
    whatWeAdded: [
      "Extended support for new database types - Oracle, MongoDB, PostgreSQL",
      "Data comparison functionality UI",
      "Data Profiling functionality - extended databases and reporting",
      "Synthetic data generation for user defined schemas"
    ]
  },
  {
    date: "17 May 2022",
    label: "Version 1.2.0",
    whatWeAdded: [
      "Single sign on with Azure B2C",
      "Redesigned UI with Menu restructured new Sidebar and Home page",
      "DB connections functionality implemented - add/modify/delete DB connections",
      "Synthetic data functionality extended to support user defined schemas",
      "Data profiling for MariaDB databases - under Database Operations",
      "Modified ViewDB functionality", "User manual updates"
    ],
    bugFixes: ["Security Flaws fixed", "SSL certificates implemented for X Bot to work with HTTPS"]
  },
  {
    date: "12 Nov 2021",
    label: "Version 1.1.0",
    whatWeAdded: [
      "Web UI responsiveness enhancements",
      "X-bot frame UI enhancements",
    ],
    bugFixes: ["Localization bug fixes added", "Security Flaws fixed"],
  },
  {
    date: "25 Oct 2021",
    label: "Version 1.0.1",
    whatWeAdded: [
      "Test DB page redesigned"
    ],
  },
  {
    date: "13 Oct 2021",
    label: "Version 1.0.0",
    whatWeAdded: ["First stable version for soft launch"],
  },
  {
    date: "04 Oct 2021",
    label: "Version 0.9",
    whatWeAdded: [
      "Logging and telemetry feature using Microsoft Application Insights",
      "Azure logger implemented"
    ],
    bugFixes: [
      "CSS style issue fixes in popup messages, footer and scroll bar",
    ],
  },
  {
    date: "24 Sep 2021",
    label: "Version 0.8",
    whatWeAdded: [
      '"Synthetic data" generation using "Mockaroo" interface Changes to default display of DataGuru landing page',
      'Enable "Synthetic Data" menu by default',
      "Disable other menu options when Test DB is not connected",
      "Implemented loader for start index button",
    ],
  },
  {
    date: "21 Sep 2021",
    label: "Version 0.7",
    bugFixes: [
      'X-Bot Bug fix for error "I am facing some issues, please try again later!!!"',
    ],
  },
  {
    date: "17 Sep 2021",
    label: "Version 0.6",
    whatWeAdded: [
      "Moved Generate Data, View Jobs, Index Test data from Database",
      "Operations to Administration Screen",
      "Set Test DB Page Added",
      "Test DB Connection Implemented",
      "Moved Set workspace functionalities to current test DB",
      "Disabled delete all records functionality",
    ],
  },
  {
    date: "09 Sep 2021",
    label: "Version 0.5",
    whatWeAdded: ["Implementation of X-Bot for synthetic data generation"],
  },
  {
    date: "08 Sep 2021",
    label: "Version 0.4",
    whatWeAdded: [
      "Sorting of Search Table in Search Page",
      "Download CSV feature Added",
      "Localization for Data operation",
      "CSS Styles/Responsiveness added",
    ],
  },
  {
    date: "03 Sep 2021",
    label: "Version 0.3",
    whatWeAdded: [
      "Localization for Database operation",
      "Delete All records functionality implemented in Test DB Table",
    ],
    bugFixes: [
      "CSS Style issues Fixed in Table Borders, Checkbox alignment and font color",
    ],
  },
  {
    date: "27 Aug 2021",
    label: "Version 0.2",
    whatWeAdded: [
      "Delete 25 records functionality implemented",
      "Pagination added for Test DB table"
    ],
  },
  {
    date: "19 Aug 2021",
    label: "Version 0.1",
    whatWeAdded: [
      "First version of DataGuru ",
      "DB operation feature added",
      "Implemented localization in French & German",
      "Landing page, Admin Screen responsiveness enhanced",
      '"Synthetic Data" generation feature added',
    ],
    bugFixes: ["Resolved Redux State issue from the chrome Dev Tools"],
  },
];
