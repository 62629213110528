import { AxiosError } from "axios";
import axiosInstance from "../../../utils/leoAxios";
import { UserDetailsType } from "../authentication/interfaces";

export const getAllUserDetails = (): any => async (dispatch: any) => {
  const response = await axiosInstance({
    method: "GET",
    url: "/api/User/getAllUsers"
  }).then((res) => {
    return res.data;
  }).catch((err: AxiosError) => err.response?.data);
  return response;
};

export const updateUserDetails = (userDetails: UserDetailsType) => async (dispatch: any) => {
  const response = await axiosInstance({
    method: "PUT",
    url: "/api/User/" + userDetails.userId + "/updateUser",
    data: JSON.stringify(userDetails)
  }).then((res) => {
    return res.data;
  }).catch((err: AxiosError) => err.response?.data);
  return response;
};