import React, { useEffect } from 'react'
import { DraggableProvided } from 'react-beautiful-dnd';
import { AssessmentDetailsObjTypeWithoutID, AssessmentModuleType, AssessmentQuestionType } from '../../../../../store/actions/assessmentManagement/assessmentManagementInterface';
import { AddIcon, CancelIcon, DeleteIcon, DragIcon, DuplicateIcon } from '../../../../reusableComponents/svgIcons'

interface Props {
  provided: DraggableProvided
  index: number
  currentModuleIndex: number
  assessmentDetails: AssessmentDetailsObjTypeWithoutID
  questionData: AssessmentQuestionType
  setEditingModule: React.Dispatch<React.SetStateAction<AssessmentModuleType>>
  setAssessmentDetails: React.Dispatch<React.SetStateAction<AssessmentDetailsObjTypeWithoutID>>
  editingModule: AssessmentModuleType
}

const QuestionForm = ({ provided, index, currentModuleIndex, assessmentDetails, setAssessmentDetails, questionData, setEditingModule, editingModule }: Props) => {

  // add new option Handler
  const addOptionHandler = (qIndex: number, oIndex: number) => {
    const uData: AssessmentQuestionType[] = editingModule.questions.map((questionData, targetQindex) => {
      if (targetQindex === qIndex && questionData.options) {
        return ({
          ...questionData, options: questionData.options.map((optionData, targetOindex) => {
            if (targetOindex === oIndex) {
              return ({ ...optionData, values: [...optionData.values, { name: "", weightage: "" }] })
            }
            return optionData;
          })
        })
      }
      return questionData;
    })
    setAssessmentDetails({ ...assessmentDetails, modules: assessmentDetails.modules.map((aData, aIndex) => aIndex === (currentModuleIndex - 1) ? ({ ...aData, questions: uData }) : aData) });
    // setEditingModule({ ...editingModule, questions: uData });
  }
  // discription input Handler
  const discriptionHandler = (e: React.ChangeEvent<HTMLTextAreaElement>, sourceIndex: number) => {
    const uData: AssessmentQuestionType[] = editingModule.questions.map((questionData, targetQindex) =>
      targetQindex === sourceIndex ? ({ ...questionData, description: e.target.value }) : questionData);
    setAssessmentDetails({ ...assessmentDetails, modules: assessmentDetails.modules.map((aData, aIndex) => aIndex === (currentModuleIndex - 1) ? ({ ...aData, questions: uData }) : aData) });
  };

  // Option label input Handler
  const lableHandler = (e: React.ChangeEvent<HTMLInputElement>, qIndex: number, oIndex: number) => {
    const uData: AssessmentQuestionType[] = editingModule.questions.map((questionData, targetQindex) =>
      targetQindex === qIndex && questionData.options ? ({
        ...questionData, options: questionData.options.map((optionData, targetOindex) => {
          if (targetOindex === oIndex) {
            return ({ ...optionData, name: e.target.value })
          }
          return optionData;
        })
      }) : questionData)
    setAssessmentDetails({ ...assessmentDetails, modules: assessmentDetails.modules.map((aData, aIndex) => aIndex === (currentModuleIndex - 1) ? ({ ...aData, questions: uData }) : aData) });
  }

  // Option label input Handler
  const optionHandHandler = (e: React.ChangeEvent<HTMLInputElement>, qIndex: number, oIndex: number, vIndex: number) => {
    const uData: AssessmentQuestionType[] = editingModule.questions.map((questionData, targetQindex) =>
      targetQindex === qIndex && questionData.options ? ({
        ...questionData, options: questionData.options.map((optionData, targetOindex) => {
          if (targetOindex === oIndex) {
            return ({ ...optionData, values: optionData.values.map((vData, targetVData) => targetVData === vIndex ? ({ ...vData, name: e.target.value }) : vData) })
          }
          return optionData;
        })
      }) : questionData)
    setAssessmentDetails({ ...assessmentDetails, modules: assessmentDetails.modules.map((aData, aIndex) => aIndex === (currentModuleIndex - 1) ? ({ ...aData, questions: uData }) : aData) });
  }

  const optionDeleteHandHandler = (qIndex: number, oIndex: number, vIndex: number) => {
    const uData: AssessmentQuestionType[] = editingModule.questions.map((questionData, targetQindex) =>
      targetQindex === qIndex && questionData.options ? ({
        ...questionData, options: questionData.options.map((optionData, targetOindex) => {
          if (targetOindex === oIndex) {
            return ({ ...optionData, values: optionData.values.filter((_vData, targetVIndex) => targetVIndex !== vIndex) })
          }
          return optionData;
        })
      }) : questionData)
    setAssessmentDetails({ ...assessmentDetails, modules: assessmentDetails.modules.map((aData, aIndex) => aIndex === (currentModuleIndex - 1) ? ({ ...aData, questions: uData }) : aData) });
  }

  const optionWeightageHandHandler = (e: React.ChangeEvent<HTMLInputElement>, qIndex: number, oIndex: number, vIndex: number) => {
    const uData: AssessmentQuestionType[] = editingModule.questions.map((questionData, targetQindex) =>
      targetQindex === qIndex && questionData.options ? ({
        ...questionData, options: questionData.options.map((optionData, targetOindex) => {
          if (targetOindex === oIndex) {
            return ({ ...optionData, values: optionData.values.map((vData, targetVData) => targetVData === vIndex ? ({ ...vData, weightage: e.target.value }) : vData) })
          }
          return optionData;
        })
      }) : questionData)
    setAssessmentDetails({ ...assessmentDetails, modules: assessmentDetails.modules.map((aData, aIndex) => aIndex === (currentModuleIndex - 1) ? ({ ...aData, questions: uData }) : aData) });
  }

  // duplicateQuestionHandler
  const duplicateQuestionHandler = () => {
    const copiedData: AssessmentQuestionType[] = [];
    editingModule.questions.map((questionData, targetQindex) => {
      if (targetQindex === index && questionData.options) {
        copiedData.push(questionData);
      }
      return questionData;
    })
    const newArr: AssessmentQuestionType[] = editingModule.questions.slice();
    newArr.splice(index, 0, copiedData[0])
    console.log(newArr)
    setAssessmentDetails({ ...assessmentDetails, modules: assessmentDetails.modules.map((moduleData, mIndex) => (mIndex == currentModuleIndex - 1) ? ({ ...moduleData, questions: newArr }) : moduleData) });
  }

  // deleteQuestionHandler
  const deleteQuestionHandler = () => {
    setAssessmentDetails({ ...assessmentDetails, modules: assessmentDetails.modules.map((aData, aIndex) => aIndex === (currentModuleIndex - 1) ? ({ ...aData, questions: aData.questions.filter((_vData, targetVIndex) => targetVIndex !== index) }) : aData) });
  }

  return (
    <div className={`questionBoxChild pb-4 pt-2 ${editingModule.questions.length == 1 ? "" : "mb-2"}`}>
      <div className='d-flex'>
        <div>
          <div className='questionBoxChildLeft text-center px-3'>
            <div {...provided.dragHandleProps}> <DragIcon /></div>
            <span>{`Q.${(index + 1) > 9 ? index + 1 : "0" + (index + 1)}`}</span>
          </div>
        </div>
        <div className='flex-grow-1'>
          <div className='questionBoxChildRight p-3'>
            <div className='questionFormGroup'><textarea placeholder='Type question here' value={questionData.description} onChange={(e) => discriptionHandler(e, index)} /></div>
            <div className='questionsOptionsCreation d-flex flex-wrap mt-2'>
              {questionData.options?.map((optionData, indexOfOption) => (
                <div className='col-12 col-md-6 px-1 px-md-3' key={indexOfOption}>
                  <div className='questionsOptionsCreationChild'>
                    <div className='optionLabelCreation'>
                      <input type="text" placeholder="Type option's label here" value={optionData.name} onChange={(e) => lableHandler(e, index, indexOfOption)} />
                    </div>
                    <div className='formGroup mt-2'>
                      {optionData.values.map((valueData, vIndex) => ((
                        <>
                          <div className={`d-flex align-items-center ${optionData.values.length == 1 ? "" : "mb-2"}`} key={vIndex}>
                            <input type="text" className='flex-grow-1' placeholder='Type option here'
                              value={valueData.name}
                              onChange={(e) => optionHandHandler(e, index, indexOfOption, vIndex)} />
                            {optionData.values.length > 1 && <button onClick={() => optionDeleteHandHandler(index, indexOfOption, vIndex)}><CancelIcon /></button>}
                          </div>
                          <div><input type="number" value={valueData.weightage} className='mb-3' min="0" placeholder='Enter weightage here' onChange={(e) => optionWeightageHandHandler(e, index, indexOfOption, vIndex)} /></div>
                        </>
                      )))}
                    </div>
                    <button className="addOptionAction d-flex align-items-center w-100 text-start mt-3 justify-content-center" onClick={() => addOptionHandler(index, indexOfOption)}>
                      <div>
                        <div className='moduleNo addModule'>
                          <AddIcon />
                        </div>
                      </div>
                      <div className='p-text ps-3'>Add Option</div>
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div>
          <div className='qestionAddOnOptions pe-3 pt-4'>
            <button onClick={duplicateQuestionHandler}><DuplicateIcon /></button> <br />
            <button className='mt-4' onClick={deleteQuestionHandler} disabled={editingModule.questions.length < 2}><DeleteIcon /></button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default QuestionForm