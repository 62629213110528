/**Imports of React libraries */
import React, { useState } from 'react';
/**Imports of SVG Icons */
import DevOpsImg from '../../../../assets/images/assessments/devSecOps-img-1.png'
import EmptyScreenVector from "../../../../assets/images/avatars/assessmentEmptySvg.svg";
/**Import of Config files */
import { config } from '../../../../config/clientConfig';
import AssessmentListView from '../../../reusableComponents/assessmentListView';
import { AssessmentResponseWithAType } from '../../../../store/actions/assessmentManagement/assessmentResponseType';
import { AssessmentDetailsObjType } from '../../../../store/actions/assessmentManagement/assessmentManagementInterface';

interface Props {
  listOfAssessments: AssessmentResponseWithAType[];
  setIsAssessmentActive: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedAssessment: React.Dispatch<React.SetStateAction<AssessmentResponseWithAType>>;
}

/** Main function */
const AssessmentList = ({ listOfAssessments, setIsAssessmentActive, setSelectedAssessment }: Props) => {
  const startAssessmentHandler = (data: AssessmentResponseWithAType) => {
    setSelectedAssessment(data);
    // console.log("data", data)
    setIsAssessmentActive(config.positive);
  }

  return (
    <section>
      <div className='myAssessmentsList'>
        {
          listOfAssessments.length > 0 ? listOfAssessments.map((data: AssessmentResponseWithAType, index: number) => (
            <div key={index}>
              {
                data.assessment.map((assessmentData: AssessmentDetailsObjType, indexI: number) =>
                  assessmentData.isPublished &&
                  (
                    <AssessmentListView
                      isViewFromOwnAssessment={config.positive}
                      assessmentData={assessmentData}
                      assessmenActiontHandler={startAssessmentHandler}
                      aditionalData={data}
                      key={indexI}
                    />
                  ))
              }
            </div>
          )) : <div className='emptyAssessmentScreen px-3 pt-2 pb-5'>
            <div>
              <img src={EmptyScreenVector} alt="EmptyScreenVector" />
              <p className='p-text text-center'>Assessment is empty, <br />
                <span>There is no assessment assigned for you</span></p>
            </div>
          </div>
        }
      </div>
    </section>
  )
}

export default AssessmentList