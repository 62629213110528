export const config = {
  companyName: "Expleo",
  AppVersion: "1.0 Beta",
  // azureADClientID: "52686846-759d-417c-90f1-153805db6480", // local
  azureADClientID: "cfaf6522-1215-4ce9-bc1b-3ba2759909d7", // dev
  // apiRootPath: "http://localhost:44447/", // local
  apiRootPath: "https://40.118.110.8/", // dev
  // apiRootPath: process.env.REACT_APP_SERVER_ROOT_PATH,
  authAppPath: process.env.REACT_APP_AUTH_APP_PATH,
  authAppValidate: process.env.REACT_APP_AUTH_APP_VALIDATE,
  authRefreshTokenPath: process.env.REACT_APP_AUTH_APP_REFRESH_TOKEN_PATH,
  tokenExpiryCheck: 600000,
  positive: true,
  negative: false
};

export const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];