export const variables = {
    dataProfilingTableText: "table",
    dataProfilingGraphText: "graph",
    dataProfilingNullChartText: "nullChart",
    dataProfilingUniqueChartText: "uniqueChart",
    dataProfilingNullValueText: "NullValues",
    dataProfilingUniqueCountText: "UniqueCount"
}

export const assessmentTypes = [
    { typenme: "Devops Maturity", reportTypes: ["Summary 9 Pillar","Summary deep-dive topics"] },
    { typenme: "TPA", reportTypes: ["TPA checklist"] },
    { typenme: "Survey", reportTypes: ["General"] },
    { typenme: "Supplier Assessment", reportTypes: ["General"] },
];