/**Imports of React libraries */
import React, { useEffect, useState } from "react";

// Pro sidebar
import { MenuItem, SubMenu } from "react-pro-sidebar";

// Import navigation link
import { Link, useLocation } from "react-router-dom";
import { getAllowedRoutes } from "../../utils/sidebar";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducers/rootReducer";

interface Props {
  items?: any;
}

function SidebarMenuChilds({ items }: Props) {
  const [allowedRoutes, setAllowedRoutes] = useState([]);
  const userDetails = useSelector((state: RootState) => state.authenticationReducer.userDetails);
  useEffect(() => {
    const restrictedRoutes = getAllowedRoutes(items);
    setAllowedRoutes(restrictedRoutes);
  }, [userDetails]);
  const location = useLocation();
  return (
    <>
      {allowedRoutes.map(({ path, title, children }: any, index: number) => (
          children ? (
            <SubMenu key={index} title={title}>
              <SidebarMenuChilds items={children} />
            </SubMenu>
          ) : (
            <MenuItem
              key={index}
              active={location.pathname === path}
              className="proMenuItems"
            >
              {title}
              {path && <Link to={path} />}
            </MenuItem>
          )
      ))}
    </>
  );
}

export default SidebarMenuChilds;
