import { Autocomplete, Dialog, DialogContent, Grid, TextField } from '@mui/material';
import React, { useState } from 'react'
import { AddIcon, CancelIcon, DownArrowIcon, RemoveIcon } from '../../reusableComponents/svgIcons';
/** Import of Icons */
import { ReactComponent as Warning } from '../../../assets/images/avatars/Invalid.svg';

interface Props {
    setIsCreateUserPopupEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  }

function CreateUserPopup({ setIsCreateUserPopupEnabled }: Props) {
    const [isnewConnectionOpen, setIsnewConnectionOpen] = useState<boolean>(true);
    /** State for store Query details from the user */
    const [newUserInput, setNewUserInput] = useState({
        firstName: "",
        lastName: "",
        email: "",
        role: ""
    });
    const [emailError, setEmailError] = useState('');

    /**Function for handle first name text in the form */
    const handleFirstNameInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewUserInput({ ...newUserInput, firstName: e.target.value });
    };
    /**Function for handle last name in the form */
    const handleLastNameInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewUserInput({ ...newUserInput, lastName: e.target.value });
    };
    /**Function for handle email text in the form */
    const handleEmailInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmailError("");
        const emailRegex = /^[a-zA-Z]+@expleogroup.com/i;
        if(!emailRegex.test(e.target.value))
            setEmailError("Invalid Email address");
        else
            setEmailError("");
            setNewUserInput({ ...newUserInput, email: e.target.value });
    };
    /**Function for handle role text in the form */
    const handleRoleInput = (newValue: string) => {
        setNewUserInput({ ...newUserInput, role: newValue });
    };

    const handleSave = () => {
        console.log(newUserInput);
    }
    
    // new connection popup close function
    const handleNewConnectionClose = () => {
        setIsnewConnectionOpen(!isnewConnectionOpen);
        // Making false add connection popup state in the listofdb component
        setIsCreateUserPopupEnabled(false);
    };
  
  return (
    <>
    {/* Add connection popup */}
    <Dialog
        open={isnewConnectionOpen}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleNewConnectionClose();
          }
        }}
        maxWidth={"md"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="newConnectionPopup"
      >
        <DialogContent>
            <div className='createUserPopup'>
                <div className='d-flex flex-wrap justify-content-between'>
                    <div>
                        <h4><b>Create User</b></h4>
                    </div>   
                    <button className='cancelButton' onClick={handleNewConnectionClose}><CancelIcon /></button>
                </div>
                <div className='p-5 createUserItems'>
                    <div className='d-flex flex-wrap justify-content-between'>
                        <div className="d-flex flex-column col-md-5">
                            <input type="text" required className="order-2 inputField p-3 mb-3" value={newUserInput.firstName} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFirstNameInput(e)} />
                            <label className="order-1 mb-2"><b>First Name</b></label>
                            {newUserInput.firstName === "" && (
                            <div className="d-flex flex-nowrap align-items-center invalid-key order-3">
                                <Warning />
                                <p>First name field is required</p>
                            </div>
                        )}
                        </div>
                        <div className="d-flex flex-column col-md-5">
                            <input type="text" required className="order-2 inputField p-3 mb-3" value={newUserInput.lastName} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleLastNameInput(e)} />
                            <label className="order-1 mb-2"><b>Last Name</b></label>
                            {newUserInput.lastName === "" && (
                            <div className="d-flex flex-nowrap align-items-center invalid-key order-3">
                                <Warning />
                                <p>Last name field is required</p>
                            </div>
                        )}
                        </div>
                    </div>
                    <div className="d-flex flex-column">
                        <input type="text" required className="order-2 inputField p-3 mb-3" value={newUserInput.email} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleEmailInput(e)} />
                        <label className="order-1 mb-2"><b>Email ID</b></label>
                        {newUserInput.email === "" && (
                            <div className="d-flex flex-nowrap align-items-center invalid-key order-3">
                                <Warning />
                                <p>Email field is required</p>
                            </div>
                        )}
                    </div>
                    <div className='selectFormGroup'>
                        <label htmlFor='reportType' className=' mb-2'><b>Select Role</b></label>
                        <Autocomplete
                            style={{ width: "100%" }}
                            popupIcon={<DownArrowIcon />}
                            className='mb-3'
                            id="reportType"
                            options={["Admin","Participant"]}
                            value={newUserInput.role}
                            sx={{ width: 300 }}
                            renderInput={(params) => 
                            <TextField {...params}
                            inputProps={{
                                ...params.inputProps,
                                "data-testid": "object",
                              }}
                            placeholder="Admin" />}
                            onChange={(e, newValue: any) => handleRoleInput(newValue)}
                        />
                    </div>
                    {newUserInput.role === "Admin" && (
                    <div className='mb-3'>
                        <span className=''><b>Access Persmission -</b> Usercreation, Assessment &amp; Project creation and View reports</span>
                    </div>
                    )}
                    {newUserInput.role === "Participant" && (
                    <div className='mb-3'>
                        <span className=''><b>Access Persmission -</b> Assigned assessment</span>
                    </div>
                    )}
                    <div className=' col-12 col-md-12 col-sm-12 pe-0 pe-md-3mt-5'>
                        <label htmlFor='reportType' className=' mb-2'><b>Select Projects</b></label>
                        <div className="d-flex flex-wrap  align-items-center justify-content-center PVUsersRow mb-5">
                            <div className='PVUsersChild ps-1 py-1 mb-2 me-2 pe-5 mt-2'>
                                <div className="d-flex align-items-center">
                                    <div><span>PN</span></div>
                                    <div className='PVUserName ps-2'>ProjectName 1</div>
                                    <button className='PVRemoveUser'><RemoveIcon /></button>
                                </div>
                            </div>
                            <div className='PVUsersChild ps-1 py-1 mb-2 me-2 pe-5 mt-2'>
                                <div className="d-flex align-items-center">
                                    <div><span>PN</span></div>
                                    <div className='PVUserName ps-2'>ProjectName 2</div>
                                    <button className='PVRemoveUser'><RemoveIcon /></button>
                                </div>
                            </div>
                            <button className='PVUsersChild addAdminButton ps-1 py-1 pe-4 mb-2 me-2 mt-2'>
                                <div className="d-flex align-items-center">
                                    <div><span><AddIcon /></span></div>
                                    <div className='PVUserName ps-2'>
                                        <Autocomplete
                                        sx={{
                                            display: 'inline-block',
                                            '& input': {
                                            width: 200,
                                            bgcolor: 'background.paper',
                                            color: (theme) =>
                                                theme.palette.getContrastText(theme.palette.background.paper),
                                            },
                                        }}
                                        id="custom-input-demo"
                                        options={[]}
                                        renderInput={(params) => (
                                            <div ref={params.InputProps.ref}>
                                            <input type="text" {...params.inputProps} placeholder='Add' />
                                            </div>
                                        )}
                                        onChange={(e, newValue) => console.log(newValue)}
                                        />
                                    </div>
                                {/* <div className='PVUserName ps-2'>Add Admin</div> */}
                                </div>
                            </button>
                        </div>
                    </div>
                    <div className='d-flex flex-wrap justify-content-end createUserButton'>
                        <button className='cancelButton p-3 ps-5 pe-5'>Cancel</button>
                        <button className='createButton p-3 ps-5 pe-5 ms-5' onClick={handleSave}>Create</button>
                    </div>
                </div>    
            </div>
        </DialogContent>    
    </Dialog>
    </>
  )
}

export default CreateUserPopup