import { combineReducers } from "redux";
import sidebarMenuReducer from "../reducers/sidebarMenu/sidebarMenuReducer";
import assessmentManagementReducer from "./assessmentManagement/assessmentManagementReducer";
import authenticationReducer from "./authentication/authenticationReducer";
import myAssessmentsReducer from "./myAssessment/myAssessmentReducer";
import projectManagmentReducer from "./projectManagement/projectManagementReducer";

export const appReducer = combineReducers({
  sidebarData: sidebarMenuReducer,
  myAssessments: myAssessmentsReducer,
  manageAssessments: assessmentManagementReducer,
  authenticationReducer: authenticationReducer,
  projectManagment: projectManagmentReducer
});

export type RootState = ReturnType<typeof appReducer>;

const rootReducer = (state: any, action: any) => {
  if (action.type === "LOGOUT") {
    state = {};
  }

  return appReducer(state, action);
};

export default rootReducer;
