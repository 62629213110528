import { Autocomplete, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { EditIcon } from '../../../../reusableComponents/svgIcons';
import IntroImg from "../../../../../assets/images/assessments/intro-assessment-img.png";
import { AssessmentDetailsObjTypeWithoutID } from '../../../../../store/actions/assessmentManagement/assessmentManagementInterface';
import { assessmentTypes } from '../../../../../config/variables.config';
import { RootState } from '../../../../../store/reducers/rootReducer';
import { useSelector } from 'react-redux';

interface Props {
  assessmentSettings: AssessmentDetailsObjTypeWithoutID,
  setAssessmentSettings: React.Dispatch<React.SetStateAction<AssessmentDetailsObjTypeWithoutID>>
}

interface ProjectListType {
  name: string;
  projectId: string;
}

const settingsCreation = ({ assessmentSettings, setAssessmentSettings }: Props) => {
  const [imageValue, setImageValue] = useState<Blob | MediaSource>();
  const [reportTypes, setReportTypes] = useState<string[]>([]);
  const projectDetails = useSelector((state: RootState) => state.authenticationReducer.userDetails.projectDetails);
  const [projectNameList, setProjectNameList] = useState<ProjectListType[]>([]);
  const [depNameList, setDepNameList] = useState<string[]>([]);
  const [selectedProjectName, setSelectedProjectName] = useState<ProjectListType | null>(null);
  const imageHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.files && e.target.files[0] && setImageValue(e.target.files[0]);
  }

  // Once user enters in assessment settings section, we are getting the project details from Redux and populating on the Project dropdown
  useEffect(() => {
    if (assessmentSettings.project && projectDetails && projectDetails.length > 0) {
      // If user already selected project storing the selected data into local state
      const userResponse = projectDetails.find((pnData) => pnData.projectId === assessmentSettings.project);
      if (userResponse) {
        setSelectedProjectName({ name: userResponse.name, projectId: userResponse.projectId ?? "" })
      } else {
        setSelectedProjectName(null)
      }
    }
    projectDetails && projectDetails.length > 0 && setProjectNameList(projectDetails.map((pdData) => ({ name: pdData.name, projectId: pdData.projectId ?? "" })))
  }, [])

  // Input Handler for overall form inputs of setting section. Bases on the inputName we are storing the user input to the appropirate states
  const formInputChangeHandler = (e: string, inputName: string, autoCompleteValues?: string[]) => {
    const updatedValue = () => {
      switch (inputName) {
        case "assessmentName":
          return { ...assessmentSettings, title: e };
        case "assessmentType":
          autoCompleteValues && setReportTypes(autoCompleteValues);
          return { ...assessmentSettings, assessmentType: e, reportType: "" };
        case "reportType":
          return { ...assessmentSettings, reportType: e };
        case "assessmentProject":
          // User once selects the project name, appropirate department name will be stored in the "depNameList" state also storing the selected values.
          projectDetails?.map((pdData) => {
            if (pdData.projectId === e) {
              setDepNameList(pdData.departments.map(dData => dData.name));
              setSelectedProjectName({name: pdData.name, projectId: pdData.projectId});
            } else if( e === "") {
              setDepNameList([]);
              setSelectedProjectName(null);
            }
          })
          return { ...assessmentSettings, project: e };
        case "assessmentDepartment":
          return { ...assessmentSettings, department: e };
        case "assessmentDiscription":
          return { ...assessmentSettings, description: e };
        case "assessmentPD":
          return { ...assessmentSettings, publishedDate: e };
        case "assessmentED":
          return { ...assessmentSettings, endDate: e };
        case "assessmentScore":
          return { ...assessmentSettings, score: e };
        case "assessmentGapRank":
          return { ...assessmentSettings, gaprank: e };
        default:
          return assessmentSettings;
      }
    }
    setAssessmentSettings(updatedValue);
  }
  return (
    <div className='settingsCreationSection'>
      <div className='settingsImage'>
        <button className='settingsImageUpload'>
          <EditIcon />
          <input type="file" accept="image/png, image/gif, image/jpeg" multiple onChange={(e) => imageHandler(e)} className="cursor-pointer" />
        </button>
        <div className='settingsImagePreview'><img src={imageValue ? URL.createObjectURL(imageValue) : IntroImg} /></div>
      </div>
      <div className='settingsInputsForm'>
        <div className='formGroup'>
          <label htmlFor='assessmentName'>Assessment Name</label>
          <input type="text" id='assessmentName' placeholder='Type name here' value={assessmentSettings.title} onChange={(e) => formInputChangeHandler(e.target.value, e.target.id)} />
        </div>
        <div className='d-flex flex-wrap'>
          <div className='col-12 col-md-4 pe-0 pe-md-3'>
            <div className='selectFormGroup'>
              <label htmlFor='assessmentProject'>Project Name</label>
              <Autocomplete
                style={{ width: "100%" }}
                id="assessmentProject"
                options={projectNameList}
                getOptionLabel={(option) => option.name}
                value={selectedProjectName}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} placeholder="Select Project" />}
                onChange={(e, newValue) => { formInputChangeHandler(newValue ? newValue.projectId : "", "assessmentProject") }}
              />
            </div>
          </div>
          <div className='col-12 col-md-4'>
            <div className='selectFormGroup pe-0 pe-md-3'>
              <label htmlFor='assessmentDepartment'>Department Name</label>
              <Autocomplete
                style={{ width: "100%" }}
                id="assessmentDepartment"
                options={depNameList}
                value={assessmentSettings.department ? assessmentSettings.department : null}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} placeholder="Select Department" />}
                onChange={(e, newValue) => { formInputChangeHandler(newValue ? newValue.toString() : "", "assessmentDepartment") }}
              />
            </div>
          </div>
          <div className='col-12 col-md-4 pe-0 pe-md-3'>
            <div className='selectFormGroup'>
              <label htmlFor='assessmentType'>AssessmentType Type</label>
              <Autocomplete
                style={{ width: "100%" }}
                id="assessmentType"
                options={assessmentTypes}
                getOptionLabel={(options) => options.typenme}
                value={assessmentSettings.assessmentType ? { typenme: assessmentSettings.assessmentType, reportTypes: [assessmentSettings.reportType] } : null}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} placeholder="Select Category" />}
                onChange={(e, newValue) => { formInputChangeHandler(newValue ? newValue.typenme : "", "assessmentType", newValue?.reportTypes) }}
              />
            </div>
          </div>
        </div>
        <div className='d-flex flex-wrap'>
          <div className='col-12 col-md-4 pe-0 pe-md-3'>
            <div className='selectFormGroup'>
              <label htmlFor='reportType'>Report Type</label>
              <Autocomplete
                style={{ width: "100%" }}
                id="reportType"
                options={reportTypes}
                value={assessmentSettings.reportType ? assessmentSettings.reportType : null}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} placeholder="Select Project" />}
                onChange={(e, newValue) => { formInputChangeHandler(newValue ? newValue.toString() : "", "reportType") }}
              />
            </div>
          </div>
          <div className='col-12 col-md-4 pe-md-3'>
            <div className='formGroup'>
              <label htmlFor='assessmentScore'>Score</label>
              <input type="text" id='assessmentScore' placeholder='Type name here' value={assessmentSettings.score} onChange={(e) => formInputChangeHandler(e.target.value, e.target.id)} />
            </div>
          </div>
          <div className='col-12 col-md-4 pe-0 pe-md-3'>
            <div className='formGroup'>
              <label htmlFor='assessmentGapRank'>Gap Rank</label>
              <input type="text" id='assessmentGapRank' placeholder='Type name here' value={assessmentSettings.gaprank} onChange={(e) => formInputChangeHandler(e.target.value, e.target.id)} />
            </div>
          </div>
        </div>
        <div className='formGroup'>
          <label htmlFor='assessmentDiscription'>Description</label>
          <textarea id='assessmentDiscription' placeholder='Type description here' value={assessmentSettings.description} onChange={(e) => formInputChangeHandler(e.target.value, e.target.id)} />
        </div>
        <div className='d-flex flex-wrap'>
          <div className="col-12 col-md-6 pe-0 pe-md-4">
            <div className='formGroup'>
              <label htmlFor='assessmentPD'>Schedule Publish date</label>
              <input type="datetime-local" id='assessmentPD' value={assessmentSettings.publishedDate} onChange={(e) => formInputChangeHandler(e.target.value, e.target.id)} />
            </div>
          </div>
          <div className="col-12 col-md-6 ps-0 ps-md-4">
            <div className='formGroup'>
              <label htmlFor='assessmentED'>Assessment End date </label>
              <input type="datetime-local" id='assessmentED' value={assessmentSettings.endDate} onChange={(e) => formInputChangeHandler(e.target.value, e.target.id)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default settingsCreation