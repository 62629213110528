/**Imports of React libraries */
import React from "react";

// import { useSelector } from "react-redux";

// Import of sidebar component
import ProSidebarMenu from "./sidebar";

// Import Pro sidebar
import "react-pro-sidebar/dist/css/styles.css";
import { Outlet } from "react-router-dom";
import MainAppbar from "./MainAppbar";
import { useSelector } from "react-redux";
import { config } from "../config/clientConfig";

interface Props {
    children: React.ReactNode;
}

const Layout = ({ children }: Props): JSX.Element => {
  const [isCollapsed, setIsCollapsed] = React.useState(config.negative);
  const isMenuOpened = useSelector(
    (state: any) => state.sidebarData.isMenuOpened
  );

  return (
    <>
      <MainAppbar />
      <div className={`mainApp ${isCollapsed ? "collapsed" : ""} ${isMenuOpened ? "menuActive" : ""
        }`}>
        <aside className="sidebarAside">
          <ProSidebarMenu
            isCollapsed={isCollapsed}
            setIsCollapsed={setIsCollapsed}
          />
        </aside>
         <div className="mainPages">{children}</div>
      </div>
    </>
  );
}

export default Layout;
