import { config } from "../../../config/clientConfig";
import { MENU_OPEN, MENU_CLOSE } from "../../actions/actiontypes";

const initialState = {
  isMenuOpened: config.negative,
};

export default function sidebarMenuReducer(state = initialState, action: any) {
  switch (action.type) {
    case MENU_OPEN:
      return {
        isMenuOpened: action.payload,
      };
    case MENU_CLOSE:
      return {
        isMenuOpened: action.payload,
      };
    default:
      return state;
  }
}
