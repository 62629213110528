import React from 'react'
/**Imports of image assets */
import IntroImg from "../../../../assets/images/assessments/intro-assessment-img.png";
import { getStandardFormatDate } from '../../../../utils/getStandardFormatDate';
import { AssessmentDetailsObjType, AssessmentModuleType, AssessmentQuestionType } from '../../../../store/actions/assessmentManagement/assessmentManagementInterface';

interface Props {
    assessmentData: AssessmentDetailsObjType
    nextModuleHandler: (d: any) => void
    writingAssessmentDetails: any
}

const AssessmentIntro = ({ assessmentData, nextModuleHandler, writingAssessmentDetails }: Props) => {
    return (
        <>
            <div className='introModule'>
                <div className='introModuleTop d-flex w-100 align-items-end pb-3 px-4'>
                    <div className='assessmentBGImg'>
                        <img src={IntroImg} alt="Image of assessment" loading="lazy" />
                    </div>
                    <div className='introTopFloatingContent w-100'>
                        <div className='d-flex flex-wrap align-items-end justify-content-between'>
                            <div className='floatingConLeft'>
                                <h6 className='subtitle-text'>{assessmentData.assessmentType}</h6>
                                <h5 className='subtitle-text'>{assessmentData.title}</h5>
                                <div className='p-text mt-2'>Status: <b>{writingAssessmentDetails.status}</b></div>
                            </div>
                            <div className='floatingConRight'>
                                <table>
                                    <tbody>
                                        <tr className='poDate text-nowrap'>
                                            <td className='pr-2'>Published on</td>
                                            <td><b>&nbsp; : {assessmentData.publishedDate && getStandardFormatDate(assessmentData.publishedDate)}</b></td>
                                        </tr>
                                        <tr className='poDate text-nowrap'>
                                            <td>End Date</td>
                                            <td><b>&nbsp; : {assessmentData.endDate && getStandardFormatDate(assessmentData.endDate)}</b></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                {assessmentData.modules?.map((data: AssessmentModuleType, index: number) => index === 0 && (
                    <div className='introModuleContent px-4' key={index}>
                        <div className='mt-4'>
                            <h5 className='p-text'>Discription</h5>
                            <p className='p-text mt-2'>{assessmentData.description}</p>
                        </div>
                        <div className='introInstructions mt-4'>
                            <h5 className='p-text'>Instructions</h5><br />
                            <div className='d-flex flex-wrap'>
                                {data.questions.map((questionData: AssessmentQuestionType, index: number) => (
                                    <div key={index} className='col-12 col-md-6 mb-5'>
                                        <div className='d-flex align-items-center'>
                                            <div>
                                                <div className='IINo'>0{index + 1}</div>
                                            </div>
                                            <p className='p-text ps-3 pe-5'>{questionData.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ))
                }
                {/* <div className='introModuleContent px-4'>
              <div className='mt-4'>
                <h5 className='p-text'>Discription</h5>
                <p className='p-text mt-2'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry{`'`}s standard dummy text ever since the 1500s</p>
              </div>
              <div className='introInstructions mt-4'>
                <h5 className='p-text'>Instructions</h5><br />
                <div className='d-flex flex-wrap'>
                  {[0, 1, 2, 3].map((data, index) => (
                    <div key={index} className='col-12 col-md-6 mb-5'>
                      <div className='d-flex align-items-center'>
                        <div>
                          <div className='IINo'>0{index + 1}</div>
                        </div>
                        <p className='p-text ps-3 pe-5'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div> */}
            </div>
            {/* <div className='assesmentWPActions d-flex justify-content-end align-items-center px-4'>
                <div>
                    <div className="customButton">
                        <button onClick={nextModuleHandler}><div>Next</div><div className='ps-3'><NextArrowIcon /></div></button>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default AssessmentIntro