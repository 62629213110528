import React, { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { AssessmentDetailsObjTypeWithoutID, AssessmentModuleType, AssessmentQuestionType } from '../../../../../store/actions/assessmentManagement/assessmentManagementInterface'
import { AddIcon, CancelIcon, DragIcon } from '../../../../reusableComponents/svgIcons'
import QuestionForm from './questionForm'

interface Props {
  currentModuleIndex: number
  assessmentDetails: AssessmentDetailsObjTypeWithoutID
  setAssessmentDetails: React.Dispatch<React.SetStateAction<AssessmentDetailsObjTypeWithoutID>>
}

const QuestionsCreation = ({ currentModuleIndex, assessmentDetails, setAssessmentDetails }: Props) => {
  const initalValues: AssessmentQuestionType = { name: "", description: "", type: "singledropdown", options: [{ name: "", values: [{ name: "", weightage: "" }] }] };
  const [editingModule, setEditingModule] = useState<AssessmentModuleType>({ name: "", description: "", questions: [] });
  useEffect(() => {
    assessmentDetails.modules.map((data, index: number) => {
      if (index === (currentModuleIndex - 1)) {
        setEditingModule({ ...data, questions: data.questions.length > 0 ? data.questions : [{ ...initalValues}] });
      }
    });
    // return () => {
    //   const temp = { ...assessmentDetails }
    //   // const currentObj = [{...editingModule}]
    //   temp.modules.map((data, index: number) => (index === currentModuleIndex) || editingModule);
    //   console.log("temp", temp);
    // }
  }, [assessmentDetails, currentModuleIndex]);

  // useEffect(() => {

  // }, [currentModuleIndex])

  /** This funtion coming from the React Beautify DND package.
    * After the Drag event we're changing the order of the form fields using splice.
  */
  const handleDragEnd = (result: DropResult) => {
    //if no destination exits(cancel event), exit this function
    if (!result.destination) return;
    if ((result.destination.index == result.source.index)) return;
    const items = Array.from(editingModule.questions);
    const [reorderedItem] = items.splice(result.source.index, 1);
    // console.log("reorderedItem", result.source.index, result.destination.index, currentModuleIndex);
    items.splice(result.destination.index, 0, reorderedItem);
    // setCurrentModuleIndex(result.source.index == (currentModuleIndex + 1) ? result.destination.index + 1 : result.destination.index == (currentModuleIndex + 1) ? result.source.index + 1 : currentModuleIndex);
    setAssessmentDetails({ ...assessmentDetails, modules: assessmentDetails.modules.map((moduleData, mIndex) => (mIndex == currentModuleIndex - 1) ? ({...moduleData, questions: items}) : moduleData) });
  };

  // add new question Handler
  const AddQuestionHandler = () => {
    // name: `Q.${editingModule.questions.length > 9 ? editingModule.questions.length + 1 : "0" + (editingModule.questions.length + 1)}`
    setAssessmentDetails({...assessmentDetails, modules: assessmentDetails.modules.map((aData, aIndex) => aIndex === (currentModuleIndex - 1) ? ({...aData, questions: [...aData.questions, {...initalValues}]}) : aData)});
    // setEditingModule({ ...editingModule, questions: [...editingModule.questions, { ...initalValues, name: `Q.${editingModule.questions.length > 9 ? editingModule.questions.length + 1 : "0" + (editingModule.questions.length + 1)}` }] })
  }

  return (
    <div className='questionsCreation ps-2'>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="assessmentQuestionsDrop">
          {(provided): JSX.Element => (
            <div className='questionBox' {...provided.droppableProps} ref={provided.innerRef}>
              {editingModule.questions.map((questionData, index) => (
                <Draggable
                  key={index}
                  draggableId={index.toString()}
                  isDragDisabled={editingModule.questions.length === 1}
                  index={index}>
                  {(provided, snapshot): JSX.Element => (
                    <div
                      {...provided.draggableProps}
                      ref={provided.innerRef}
                      tabIndex={-1}
                      key={index}
                      className={
                        `${snapshot.isDragging ? "selected" : "not-selected"}`
                      }>
                      <QuestionForm 
                        provided={provided}
                        index={index}
                        currentModuleIndex={currentModuleIndex}
                        assessmentDetails={assessmentDetails}
                        setAssessmentDetails={setAssessmentDetails}
                        questionData={questionData} 
                        editingModule={editingModule} 
                        setEditingModule={setEditingModule} />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className='addquestionAction mt-4'>
        <div className="customButton">
          <button onClick={AddQuestionHandler}>
            <div className='pe-3'><AddIcon /></div>
            <div>Add Questions</div>
          </button>
        </div>
      </div>
    </div>
  )
}

export default QuestionsCreation