/**Imports of React libraries */
import React, { useEffect } from 'react';
/**Imports of Material UI Elements */
import { Breadcrumbs } from '@mui/material';
import { useDispatch } from 'react-redux';
import ModulesSidebar from './modulesSidebar';
import AssessmentIntro from './assessmentIntro';
import AssessmentWriterForm from './assessmentWriterForm';
import { NextArrowIcon, PrevArrowIcon } from '../../../reusableComponents/svgIcons';
import { isObjectEmpty } from '../../../../utils/objectUtils';
import { config } from '../../../../config/clientConfig';
import { AssessmentQuestionResponse, AssessmentResponseModule, AssessmentResponseType, AssessmentResponseWithAType } from '../../../../store/actions/assessmentManagement/assessmentResponseType';
import { AssessmentDetailsObjType, AssessmentModuleType, AssessmentQuestionType } from '../../../../store/actions/assessmentManagement/assessmentManagementInterface';
import { generateUserResponsedDetails, getInvalidResponsesQuinicData } from '../../../../utils/filterUserResponse';
import { saveUserResponse } from '../../../../store/actions/myAssessments/myAssessmentsAction';

interface Props {
  setIsAssessmentActive: React.Dispatch<React.SetStateAction<boolean>>,
  selectedAssessment: AssessmentResponseWithAType
}

const AssessmentWriter = ({ setIsAssessmentActive, selectedAssessment }: Props) => {
  // dispatch method to call action
  const dispatch = useDispatch();
  //Receive the selected assessment details from the redux store
  const writingAssessmentDetails = selectedAssessment;
  const { assessment, ...responseDetails } = selectedAssessment;
  // state for store if user responsed already
  const [userResponseData, setUserResponseData] = React.useState<AssessmentResponseType>(responseDetails);
  // state for store current assessment data
  const [assessmentData, setAssessmentData] = React.useState<AssessmentDetailsObjType>({} as AssessmentDetailsObjType);
  // state for store current module details
  const [currentModule, setCurrentModule] = React.useState<AssessmentModuleType>({} as AssessmentModuleType);
  const [currentModuleIndex, setCurrentModuleIndex] = React.useState<number>(0);

  // useEffect(() => {
  //   let ignore = config.negative;
  //   !ignore && isObjectEmpty(selectedAssessment) && dispatch(getAssessmentsDetails(selectedAssessment.assessmentId));
  //   return () => { ignore = config.positive }
  // }, []);

  // Here we are mapping the user response with assessment details
  const mapUserResponseData = (apData: AssessmentDetailsObjType, userResponseData: AssessmentResponseType) => {
    // Create a mapping function for questions
    const mapQuestion = (apMQData: AssessmentQuestionType, matchingQuestion: AssessmentQuestionResponse | undefined) => {
      if (matchingQuestion) {
        return {
          ...apMQData,
          options: apMQData.options?.map(apMMData => {
            const matchingResponse = matchingQuestion.multireponses?.find(urRData => urRData.name === apMMData.name);
            return matchingResponse ? { ...apMMData, answer: matchingResponse.answer } : apMMData;
          }),
        };
      }
      return apMQData;
    };

    // Create a mapping function for modules
    const mapModule = (apMData: AssessmentModuleType, matchingModule: AssessmentResponseModule | undefined) => {
      if (matchingModule && matchingModule.reponses) {
        return {
          ...apMData,
          status: matchingModule.status ? matchingModule.status : "Not Started",
          questions: apMData.questions?.map(apMQData => {
            const matchingQuestion = matchingModule.reponses?.find(urRData => urRData.name === apMQData.description);
            return mapQuestion(apMQData, matchingQuestion);
          }),
        };
      }
      return apMData;
    };

    // Map the user response data to the assessment data
    const mappedUserResponseData = {
      ...apData,
      modules: apData.modules.map(apMData => {
        const matchingModule = userResponseData.modules?.find(urMData => urMData.name === apMData.name);
        console.log(matchingModule);
          return mapModule(apMData, matchingModule);
      }),
    };

    return mappedUserResponseData;
  }

  // Here we are mapping the user response with assessment details
  useEffect(() => {
    if (isObjectEmpty(writingAssessmentDetails)) {
      const apData = writingAssessmentDetails.assessment[0];
      const mappedData = userResponseData?.modules ? mapUserResponseData(apData, userResponseData) : apData;
      setAssessmentData({...mappedData, modules: mappedData.modules.map((ds) => ds.name === "Introduction" ? ({...ds, status: "Completed"}) : ds)});
    }
  }, [writingAssessmentDetails]);

  useEffect(() => {
    if (isObjectEmpty(assessmentData)) {
      assessmentData.modules.map((data: any, index: number) => {
        if (index === currentModuleIndex) {
          setCurrentModule(data);
        }
      });
    }
  }, [currentModuleIndex, assessmentData]);

  const nextModuleHandler = () => {
    setAssessmentData({...assessmentData, modules: getInvalidResponsesQuinicData(assessmentData).updatedStatus});
    setCurrentModuleIndex(currentModuleIndex + 1);
  }

  const prevModuleHandler = () => {
    setAssessmentData({...assessmentData, modules: getInvalidResponsesQuinicData(assessmentData).updatedStatus});
    setCurrentModuleIndex(currentModuleIndex - 1);
  }

  const assessmentSubmitHandler = async () => {
    const statusUpdatedData = getInvalidResponsesQuinicData(assessmentData);
    if(statusUpdatedData.resdata.length === 0){
      const updatedModuleData = await generateUserResponsedDetails({...assessmentData, modules: getInvalidResponsesQuinicData(assessmentData).updatedStatus}, userResponseData);
      setAssessmentData({...assessmentData, modules: getInvalidResponsesQuinicData(assessmentData).updatedStatus});
      await dispatch(saveUserResponse({...updatedModuleData, status: "Completed", completedOn: new Date()} as AssessmentResponseType));
    } else {
      alert("You've pending questions to answer!")
    }
  }

  return (
    <section className='assessmentWP pb-3'>
      <div className='d-flex align-items-center justify-content-between mb-4'>
        <div className='assessmentWPBreadcrumbs'>
          <Breadcrumbs separator={<NextArrowIcon />} aria-label="breadcrumb">
            <button onClick={() => setIsAssessmentActive(config.negative)}>My Assessments</button>
            <div>{assessmentData.title}</div>
          </Breadcrumbs>
        </div>
        <div className="customButton">
          <button
            type='submit'
            onClick={assessmentSubmitHandler}>
            <div>Submit</div>
            {/* <div className='ps-3'><PrevArrowIcon /></div> */}
          </button>
        </div>
      </div>
      <div className='assessmentWPMain d-flex'>
        <ModulesSidebar
          assessmentData={assessmentData}
          setCurrentModule={setCurrentModule}
          setCurrentModuleIndex={setCurrentModuleIndex}
          currentModuleIndex={currentModuleIndex}
          setAssessmentData={setAssessmentData}
        />
        <div className='modulesRightQu ps-3 pb-3'>
          {Object.keys(currentModule).length <= 0 || currentModule.name === "Introduction" ? <AssessmentIntro assessmentData={assessmentData} writingAssessmentDetails={writingAssessmentDetails} nextModuleHandler={nextModuleHandler} /> :
            <AssessmentWriterForm
              currentModule={currentModule}
              userResponseData={userResponseData}
              currentModuleIndex={currentModuleIndex}
              assessmentData={assessmentData}
              setAssessmentData={setAssessmentData}
            />
          }
          <div className='assesmentWPActions d-flex justify-content-end align-items-center px-4'>
            <div>
              <div className="customButton">
                <button
                  className={`${currentModuleIndex === 0 ? "disabledBtn" : ""}`}
                  onClick={prevModuleHandler}
                  disabled={currentModuleIndex === 0}>
                  <div>Prev</div>
                  <div className='ps-3'><PrevArrowIcon /></div>
                </button>
              </div>
            </div>
            <div className='ps-4'>
              <div className="customButton">
                <button
                  className={`${currentModuleIndex + 1 === assessmentData.modules?.length ? "disabledBtn" : ""}`}
                  onClick={nextModuleHandler}
                  disabled={currentModuleIndex + 1 === assessmentData.modules?.length}>
                  <div>Next</div>
                  <div className='ps-3'><NextArrowIcon /></div>
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
  )
}

export default AssessmentWriter