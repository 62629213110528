import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { config } from '../../../config/clientConfig';
import { editProjectDetails, removeProjectViewDetails, saveNewProject, storeNewProjectViewDetails, storeProjectViewDetails } from '../../../store/actions/projectManagement/projectManagementAction';
import { ProjectDetails } from '../../../store/actions/projectManagement/projectManagementType';
import { RootState } from '../../../store/reducers/rootReducer';
import ProjectEmpty from './projectEmpty/projectEmptyIndex';
import ProjectList from './projectList/projectListIndex'
import ProjectView from './projectView/projectViewIndex'
import { getUserDetails } from '../../../store/actions/authentication/authenticationAction';
import { getAllUserDetails, updateUserDetails } from '../../../store/actions/userManagement/userManagementAction';
import { UserDetailsType } from '../../../store/actions/authentication/interfaces';

const ProjectManagement = () => {
  const dispatch = useDispatch();
  const projectViewData = useSelector((state: RootState) => state.projectManagment.projectViewDetails);
  const isNewProjectActive = useSelector((state: RootState) => state.projectManagment.isNewProjectActive);
  const [isProjectViewActive, setIsProjectViewActive] = useState(config.negative);
  const [isProjectEditable, setIsProjectEditable] = useState(config.negative);
  const projectDetails = useSelector((state: RootState) => state.authenticationReducer.userDetails.projectDetails);
  const userDetails = useSelector((state: RootState) => state.authenticationReducer.userDetails);
  const [projectData, setProjectData] = useState<ProjectDetails>(projectViewData);
  const [usersList, setUsersList] = useState<UserDetailsType[]>([]);

  const initialGet = async () => {
    const usersInfo = await dispatch(getAllUserDetails());
    if (usersInfo.length > 0) {
      setUsersList(usersInfo as UserDetailsType[])
    }
    console.log(usersInfo);
  }

  useEffect(() => {
    let ignore = config.negative;
    initialGet();
    return () => { ignore = config.positive }
  }, [userDetails])

  useEffect(() => {
    setProjectData(projectViewData);
  }, [projectViewData])

  // handleViewIndividualProject
  const handleViewIndividualProject = async (pData: ProjectDetails, type: string) => {
    if(type === "EXISTING") {
      await dispatch(storeProjectViewDetails(pData.projectId ?? ""))
    } else if (type === "NEW") {
      await dispatch(storeNewProjectViewDetails(pData));
      await dispatch(getUserDetails());
      setIsProjectEditable(config.positive);
    } else if (type === "EDIT_PROJECT") {
      await dispatch(storeProjectViewDetails(pData.projectId ?? ""))
      setIsProjectEditable(config.positive);
    }
    setIsProjectViewActive(config.positive);
  }

  // handleEditProject
  const handleEditProject = () => {
    setIsProjectEditable(config.positive);
    setIsProjectViewActive(config.positive);
  }

  // handleEditProject
  const handleSaveProject = async () => {
    if(isNewProjectActive){
      const newProjectData = await dispatch(saveNewProject(projectData))
      const updatedUserList = usersList.map(ulData => {
        // const hasEmptyString = ulData.mappedDepts.find((tdData) => tdData.projectId === "");
        return ({
          ...ulData,
          // projects: ulData.projects.includes("") ? ulData.projects.map(pId => pId !== "" ? pId : newProjectData.projectId) : ulData.projects,
          projects: ulData.projects?.map(pId => pId.projectId).includes("") ? ulData.projects.map(pId => pId.projectId !== "" ? pId : ({...pId, projectId: newProjectData.projectId})) : ulData.projects,
          // mappedDepts: ulData.mappedDepts?.map(mdData => {
          //   return ({
          //     ...mdData, projectId: mdData.projectId === "" ? newProjectData.projectId : mdData.projectId
          //   })
          // })
        })
      })
      const promise = updatedUserList.map(async (ulData) => {
        const {projectDetails, ...restData} = ulData;
        return await dispatch(updateUserDetails(restData))
      });
      await Promise.all(promise);
    } else {
      await dispatch(editProjectDetails(projectData));
      const promise = usersList.map(async (ulData) => {
        const {projectDetails, ...restData} = ulData;
        return await dispatch(updateUserDetails(restData))
      });
      await Promise.all(promise);
    }
    await dispatch(getUserDetails());
    await initialGet();
    setIsProjectEditable(config.negative);
    alert("Project saved successfully!")
    handleBack();
  }

  // handleEditProject
  const handleBack = async () => {
    await dispatch(removeProjectViewDetails());
    setIsProjectViewActive(config.negative)
  }

  return (
    <main>
      {!isProjectViewActive ? projectDetails && projectDetails.length > 0 ? <ProjectList setIsProjectViewActive={setIsProjectViewActive} projectDetails={projectDetails} handleViewIndividualProject={handleViewIndividualProject} /> : <ProjectEmpty /> : null }
      {
        isProjectViewActive &&  projectData && 
          <ProjectView 
            isEditable={isProjectEditable} 
            usersList={usersList}
            setUsersList={setUsersList}
            projectData={projectData}
            setProjectData={setProjectData}
            setIsProjectViewActive={setIsProjectViewActive} 
            handleEditProject={handleEditProject}
            handleSaveProject={handleSaveProject}
            handleBack={handleBack}
             />
      }
    </main>
  )
}

export default ProjectManagement