/**Imports of React libraries */
import React, { useEffect, useState } from "react";
import { ReactComponent as APLogo } from "../assets/images/ap-logo.svg";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { sidebarMenuCloseAction, sidebarMenuOpenAction } from "../store/actions/sidebarMenu/sidebarMenuAction";
import { LogoutIcon, UserIcon } from "../components/reusableComponents/svgIcons";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../config/authConfig";
import { ITokenObject, UserDetailsType } from "../store/actions/authentication/interfaces";
import { validateTokenExpiry, validateUserByToken } from "../store/actions/authentication/authenticationAction";
import { RootState } from "../store/reducers/rootReducer";
import { config } from "../config/clientConfig";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import removeLocalStorageData from "../utils/removeLocalStorageData";
import Loader from "../components/loader/loader";

function MainAppbar() {
  const dispatch = useDispatch();
  const { instance, inProgress, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [isTokenExpired, setIsTokenExpired] = useState(config.negative);
  const [isLoading, setIsDataLoading] = useState<boolean>(config.positive);
  const userDetails: UserDetailsType = useSelector((state: RootState) => state.authenticationReducer.userDetails);
  const tokenExpiry: number = useSelector((state: RootState) => state.authenticationReducer.authDetails.exp);

  //To check the loading condition of auth login
  const authLoading = useSelector((state: RootState) => state.authenticationReducer.authLoading);

  // useEffect(() => {
  //   /** Condition of authlogin to stop redirecting to authlogin again */
  //   if (Object.keys(sessionStorage).length > 0) {
  //     Object.keys(sessionStorage).map((data: any) => {
  //       if (
  //         data.startsWith("msal.") &&
  //         data.endsWith(".request.correlationId")
  //       ) {
  //         dispatch(inmemoryLoading(config.negative));
  //       }
  //     });
  //   }
  // }, []);

  // useEffect(() => {
  //   if(authLoading){
  //     handleLoginRedirect();
  //   }
  // }, []);

  // // Capture token from msalinstance from the browser and validate user by passing token to external AuthApp
  const getTokenAndValidateUser = async (msalInstance: any) => {
    if (accounts.length > 0) {
      const request = {
        ...loginRequest,
        account: accounts[0]
      };
      try {
        setIsDataLoading(config.positive);
        const authResult = await msalInstance.acquireTokenSilent(request);
        if (authResult) {
          const jsonStr: string = JSON.stringify(authResult);
          const jsObj: ITokenObject = JSON.parse(jsonStr);
          // console.log(jsObj);
          jsObj && dispatch(validateUserByToken(jsObj["accessToken"], jsObj["idTokenClaims"]));
        }
        setIsDataLoading(config.negative);
      } catch (err) {
        if (err instanceof InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          return msalInstance.acquireTokenRedirect(request)
        }
      }
    }
    // else{
    //   handleLoginRedirect();
    // }
  };

  useEffect(() => {
    // console.log("isAuthenticated", isAuthenticated)
    getTokenAndValidateUser(instance);
  }, [isAuthenticated])


  setInterval(async () => {
    const isTokenExpiredStatus = await validateTokenExpiry(tokenExpiry);
    console.log(tokenExpiry, isTokenExpiredStatus);
    if (isTokenExpiredStatus === config.positive) {
      getTokenAndValidateUser(instance)
      setIsTokenExpired(config.positive);
    } else {
      setIsTokenExpired(config.negative);
    }
  }, config.tokenExpiryCheck);

  // Sidebar Menu state
  const [isSidebarOpen, setIsSidebaropen] = React.useState(config.positive);

  // tiggering sidebar from navbar for the mobile screen
  const sidebarMenuHandler = () => {
    setIsSidebaropen(!isSidebarOpen);

    // dispatching the action based on the "isSidebarOpen" state
    isSidebarOpen
      ? dispatch(sidebarMenuOpenAction(isSidebarOpen))
      : dispatch(sidebarMenuCloseAction(isSidebarOpen));
  };

  const handleLogoutRedirect = async () => {
    removeLocalStorageData()
    await instance.logoutRedirect();
  };

  const handleLoginRedirect = async () => {
    await instance.loginRedirect(loginRequest).catch((error: any) => console.log(error));
  };

  return (
    <>
    {/* {isLoading && <Loader />} */}
      <div className="topNavExtraSpace"></div>
      <div className="header-appbar px-4">
        <div className="header-toolbar d-flex align-items-center">
          <div className="headerToolbarMenu">
            <button
              className="headerToolbarMenuBtn"
              onClick={sidebarMenuHandler}
            >
              <svg
                width="26"
                height="20"
                viewBox="0 0 26 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.58056 3.42345H24.4198C25.2913 3.42345 26 2.71476 26 1.84326C26 0.971764 25.2913 0.262695 24.4198 0.262695H1.58056C0.709069 0.262695 0 0.971764 0 1.84326C0 2.71476 0.709069 3.42345 1.58056 3.42345V3.42345Z"
                  fill="white"
                />
                <path
                  d="M24.4198 8.41895H1.58056C0.709069 8.41895 0 9.12801 0 9.99951C0 10.871 0.709069 11.5797 1.58056 11.5797H24.4198C25.2913 11.5797 26 10.871 26 9.99951C26 9.12801 25.2913 8.41895 24.4198 8.41895V8.41895Z"
                  fill="white"
                />
                <path
                  d="M24.4198 16.5762H1.58056C0.709069 16.5762 0 17.2856 0 18.1571C0 19.0286 0.709069 19.7373 1.58056 19.7373H24.4198C25.2913 19.7373 26 19.0286 26 18.1571C26 17.2856 25.2913 16.5762 24.4198 16.5762Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
          <div className="header-toolbar-logo flex-grow-1">
            <Link to="/">
              <APLogo data-testid="APLogo" />
              {/* Assessment <b>Portal</b> */}
            </Link>
          </div>
          <div className="rightToolbar">
            <div className='d-flex align-items-center'>
              {userDetails && userDetails.fisrtName && <div>
                <UserIcon className="header-user-svg" />
              </div>}
              <div className="px-4">
                {userDetails && userDetails.fisrtName ?
                  <p className='m-0'>{userDetails.fisrtName}</p>
                  : <p className='m-0 cursor-pointer' onClick={handleLoginRedirect}>{"Log in"}</p>
                }
              </div>
              {userDetails && userDetails.fisrtName &&
                <div className="ps-2" style={{cursor: 'pointer'}} onClick={handleLogoutRedirect}>
                  <LogoutIcon />
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MainAppbar;
