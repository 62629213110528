import { STORE_CREATED_ASSESSMENTS } from "../../actions/actiontypes";
import { AssessmentDetailsType } from "../../actions/assessmentManagement/assessmentManagementInterface";

const initialState = {
  publishedAssessments: [] as AssessmentDetailsType,
  draftedAssessments: [] as AssessmentDetailsType
};

export default function assessmentManagementReducer(state = initialState, action: any) {
  switch (action.type) {
    case STORE_CREATED_ASSESSMENTS:
      return {
        ...state,
        publishedAssessments: action.payload.publishedAssessments,
        draftedAssessments: action.payload.draftedAssessments,
      };
    default:
      return state;
  }
}
