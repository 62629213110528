import React, { useEffect, useState } from 'react'
import { DraggableProvided } from 'react-beautiful-dnd'
import { AssessmentDetailsObjTypeWithoutID } from '../../../../../store/actions/assessmentManagement/assessmentManagementInterface'
import { DragIcon } from '../../../../reusableComponents/svgIcons'

interface Props {
  provided: DraggableProvided
  assessmentData: any
  setAssessmentDetails: React.Dispatch<React.SetStateAction<AssessmentDetailsObjTypeWithoutID>>
  setCurrentModuleIndex: React.Dispatch<React.SetStateAction<number>>
  currentModuleIndex: number
  moduleIndex: number
}

const ModulesCreationSidebar = ({ setCurrentModuleIndex, currentModuleIndex, assessmentData, setAssessmentDetails, moduleIndex, provided }: Props) => {
  // const [moduleValue, setModuleValue] = useState(assessmentData.name);
  const moduleSelectionHandler = (index: number) => {
    setCurrentModuleIndex(index);
  }
  const moduleNameHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    // setModuleValue(e.target.value);
    setAssessmentDetails((prev) => {
      return {...prev, modules: prev.modules.map((data, index) => {
        if (index === (currentModuleIndex - 1)) {
          // console.log("a",index, currentModuleIndex - 1);
          return {
            ...data,
            name: e.target.value
          }
        } else {
          return data
        }
      })}
    })
  };
  // useEffect(() => {
  //   setAssessmentDetails((prev) => {
  //     return {...prev, modules: prev.modules.map((data, index) => {
  //       if (index === (currentModuleIndex - 1)) {
  //         console.log("a",index, currentModuleIndex - 1);
  //         return {
  //           ...data,
  //           name: moduleValue
  //         }
  //       } else {
  //         return data
  //       }
  //     })}
  //   })
  // }, [moduleValue])
  return (
    <div className={`modulesList py-3 d-flex align-items-center w-100 text-start ${moduleIndex + 1 === currentModuleIndex ? "active" : ""}`}>
      <div>
        <div className='moduleNo'>{moduleIndex > 9 ? moduleIndex + 1 : "0" + (moduleIndex + 1)}</div>
      </div>
      <button className='p-text ps-2 flex-grow-1 cursor-pointer' onClick={() => moduleSelectionHandler(moduleIndex + 1)}>{moduleIndex > 0 && moduleIndex + 1 === currentModuleIndex ? <input type="text" value={assessmentData.name} autoFocus onChange={moduleNameHandler} /> : assessmentData.name}</button>
      {
        moduleIndex > 0 && <div>
          <div className='moduleStatus' {...provided.dragHandleProps}>
            <DragIcon />
          </div>
        </div>
      }
    </div>
  )
}

export default ModulesCreationSidebar