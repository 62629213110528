import React, { Dispatch, useEffect } from 'react';

interface AssessmentInstructionType {
  name: string,
  description: string,
  questions: QuestionType[]
}

interface QuestionType {
  name: string, description: string
}

interface Props {
  assessmentInstruction: AssessmentInstructionType;
  setAssessmentInstruction: Dispatch<React.SetStateAction<AssessmentInstructionType>>
}

const InstructionCreation = ({ assessmentInstruction, setAssessmentInstruction }: Props) => {
  const initalValues = { name: "", description: "" };

  useEffect(() => {
    assessmentInstruction.questions.length === 0 &&
      setAssessmentInstruction({ ...assessmentInstruction, questions: Array.from({ length: 4 }, (_, index) => ({ ...initalValues, name: "0" + (index + 1) })) });
  }, []);

  const addNewPointHandler = () => {
    setAssessmentInstruction(
      {
        ...assessmentInstruction,
        questions: [...assessmentInstruction.questions, { ...initalValues, name: assessmentInstruction.questions.length + 1 > 9 ? (assessmentInstruction.questions.length + 1).toString() : ("0" + (assessmentInstruction.questions.length + 1)) }]
      }
    );
  }

  const inputHander = (e: React.ChangeEvent<HTMLTextAreaElement>, targetIndex: number) => {
    const updatedQestions = assessmentInstruction.questions.map((data, sourceIndex) => {
      if (sourceIndex === targetIndex)
        return { ...data, description: e.target.value };
      return data
    });
    setAssessmentInstruction({ ...assessmentInstruction, questions: updatedQestions });
  }
  return (
    <div className='instructionCreationSection px-3 pt-3'>
      <div><h4 className='subtitle-text'>Instruction</h4></div>
      <div className='points mt-4'>
        {assessmentInstruction.questions.map((data, index) => (
          <div className='d-flex mb-4' key={index}>
            <div className='instNumber'>
              {data.name}
            </div>
            <div className='flex-grow-1 ps-3'>
              <div className='instFormGroup'>
                <textarea placeholder='Type instruction here' value={data.description} onChange={(e) => inputHander(e, index)} />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className='addPointAction'>
        <button onClick={() => addNewPointHandler()} className="modulesList py-3 d-flex align-items-center w-100 text-start">
          <div>
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13 0C10.4288 0 7.91543 0.762436 5.77759 2.19089C3.63975 3.61935 1.97351 5.64967 0.989571 8.02511C0.00563276 10.4005 -0.25181 13.0144 0.249797 15.5362C0.751404 18.0579 1.98953 20.3743 3.80761 22.1924C5.6257 24.0105 7.94207 25.2486 10.4638 25.7502C12.9856 26.2518 15.5994 25.9944 17.9749 25.0104C20.3503 24.0265 22.3806 22.3602 23.8091 20.2224C25.2375 18.0846 26 15.5711 26 13C25.9959 9.55343 24.625 6.24919 22.1879 3.8121C19.7508 1.37501 16.4466 0.00406601 13 0ZM18.9091 14.1818H14.1818V18.9091C14.1818 19.2225 14.0573 19.5231 13.8357 19.7447C13.614 19.9664 13.3134 20.0909 13 20.0909C12.6866 20.0909 12.386 19.9664 12.1643 19.7447C11.9427 19.5231 11.8182 19.2225 11.8182 18.9091V14.1818H7.09091C6.77747 14.1818 6.47687 14.0573 6.25524 13.8357C6.0336 13.614 5.90909 13.3134 5.90909 13C5.90909 12.6866 6.0336 12.386 6.25524 12.1643C6.47687 11.9427 6.77747 11.8182 7.09091 11.8182H11.8182V7.0909C11.8182 6.77747 11.9427 6.47687 12.1643 6.25523C12.386 6.0336 12.6866 5.90909 13 5.90909C13.3134 5.90909 13.614 6.0336 13.8357 6.25523C14.0573 6.47687 14.1818 6.77747 14.1818 7.0909V11.8182H18.9091C19.2225 11.8182 19.5231 11.9427 19.7448 12.1643C19.9664 12.386 20.0909 12.6866 20.0909 13C20.0909 13.3134 19.9664 13.614 19.7448 13.8357C19.5231 14.0573 19.2225 14.1818 18.9091 14.1818Z" fill="#6946C6" />
            </svg>
          </div>
          <div className='p-text ps-2'>Add Module</div>
        </button>
      </div>
    </div>
  )
}

export default InstructionCreation