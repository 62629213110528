import { AnyAction } from "redux";
import { config } from "../../../config/clientConfig";
import {
  SET_LOADING,
  SET_TOKEN,
  STORE_USER_DETAILS,
} from "../../actions/actiontypes";
import { IdTokenClaims, UserDetailsType } from "../../actions/authentication/interfaces";

interface InitStateType {
  newAccessToken: string
  authDetails: IdTokenClaims
  authLoading: boolean
  userDetails: UserDetailsType
}

const initialState: InitStateType = {
  newAccessToken: "",
  authDetails: {} as IdTokenClaims,
  authLoading: config.positive,
  userDetails: {} as UserDetailsType
};

// Get the username from action file and store in root state
export default function authenticationReducer(
  state = initialState,
  action: AnyAction
): InitStateType {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        newAccessToken: action.payload.token,
        authDetails: action.payload.jsObj,
        userDetails: action.payload.userDetails
      };
    case SET_LOADING:
      return {
        ...state,
        authLoading: action.payload,
      };
    case STORE_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
      };
    default:
      return state;
  }
}
