export const _objectWithoutProperties = (obj: any, keys: string[]) => {
    const target: any = {};
    for (const i in obj) {
        if (keys.indexOf(i) >= 0) continue;
        if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
        target[i] = obj[i];
    }
    return target;
}
/** To check, is the object empty or not */
export const isObjectEmpty = (obj: any) => {
    return Object.keys(obj).length > 0;
}