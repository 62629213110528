import { AxiosError } from 'axios';
import { Dispatch } from 'redux';
import { config } from '../../../config/clientConfig';
import axiosInstance from '../../../utils/leoAxios';
import { STORE_CREATED_ASSESSMENTS, STORE_EDITABLE_ASSESSMENTS } from "../actiontypes"
import { AssessmentDetailsObjTypeWithtDis, AssessmentDetailsType } from './assessmentManagementInterface';

/** Get List form assigned Assessments */
export const getCreatedAssessments = () => async (dispatch: Dispatch) => {
    const userEmail = localStorage.getItem('userEmail');
    const response = await axiosInstance({
        method: "GET",
        url: "/api/Assessment/owner/" + userEmail
    }).then((res) => {
        const resData: AssessmentDetailsType = res.data;
        const publishedAssessments: AssessmentDetailsType = [];
        const draftedAssessments: AssessmentDetailsType = [];
        if(resData.length > 0){
            resData.map(data => data.isPublished ? publishedAssessments.push(data) : draftedAssessments.push(data));
        }
        dispatch({
            payload: {publishedAssessments, draftedAssessments} || [],
            type: STORE_CREATED_ASSESSMENTS,
        });
        return res.data;
    }).catch((err: AxiosError) => err.response?.data);
    return response;
}

/** Get List form assigned Assessments */
export const createNewdAssessments = (aData: AssessmentDetailsObjTypeWithtDis) => async (dispatch: Dispatch) => {
    const response = await axiosInstance({
        method: "POST",
        url: "/api/Assessment",
        data: JSON.stringify(aData)
    }).then((res) => {
        console.log(res.data);
        return res.data;
    }).catch((err: AxiosError) => err.response?.data);
    return response;
}

/** update assigned Assessments */
export const updatedAssessments = (aId: string, aData: AssessmentDetailsObjTypeWithtDis) => async (dispatch: Dispatch) => {
    const response = await axiosInstance({
        method: "PUT",
        url: "/api/Assessment/" + aId,
        data: JSON.stringify(aData)
    }).then(async (res) => {
        const es = await dispatch(getCreatedAssessments() as any);
        console.log(es)
        dispatch({
            payload: {} || [],
            type: STORE_EDITABLE_ASSESSMENTS,
        });
        return res.data;
    }).catch((err: AxiosError) => err.response?.data);
    return response;
}