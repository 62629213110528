import React, { useEffect } from 'react'
/**Imports of Material UI Elements */
import { Autocomplete, Breadcrumbs, TextField } from '@mui/material';
import { DownArrowIcon, SaveIcon } from '../../../reusableComponents/svgIcons';
/**Import of Config files */
import training from "../../../../config/assessmentQuestions/training.json";
import { AssessmentDetailsObjType, AssessmentModuleType, AssessmentQuestionType } from '../../../../store/actions/assessmentManagement/assessmentManagementInterface';
import { AssessmentResponseType } from '../../../../store/actions/assessmentManagement/assessmentResponseType';
import { useDispatch } from 'react-redux';
import { saveUserResponse } from '../../../../store/actions/myAssessments/myAssessmentsAction';
import { generateUserResponsedDetails, getInvalidResponsesQuinicData } from '../../../../utils/filterUserResponse';

interface Props {
  currentModule: AssessmentModuleType;
  userResponseData: AssessmentResponseType;
  currentModuleIndex: number;
  assessmentData: AssessmentDetailsObjType;
  setAssessmentData: React.Dispatch<React.SetStateAction<AssessmentDetailsObjType>>;
}

const AssessmentWriterForm = ({ currentModule, userResponseData, currentModuleIndex, assessmentData, setAssessmentData }: Props) => {
  const dispatch = useDispatch();
  // const [refresh, setRefreah] = React.useState(false);

  // useEffect(() => {
  //   setRefreah(!refresh);
  //   console.log("first")
  // }, [currentModuleIndex])

  const handleDropdownChange = (value: string, sourceOpIndex: number, sourceQusIndex: number) => {
    const updatedData = assessmentData.modules.map((aData, aIndex) => {
      if (aIndex === currentModuleIndex) {
        return ({
          ...aData,
          questions: aData.questions.map((qusData, targetQusIdex) => {
            if (targetQusIdex === sourceQusIndex) {
              return ({
                ...qusData,
                options: qusData.options?.map((opData, targetOpIndex) => targetOpIndex === sourceOpIndex ? ({ ...opData, answer: value }) : opData)
              })
            } else {
              return qusData
            }
          })
        })
      } else {
        return aData
      }
    })
    setAssessmentData({ ...assessmentData, modules: updatedData });
    }

  const draftHandler = async () => {
    const updatedModuleData = await generateUserResponsedDetails({...assessmentData, modules: getInvalidResponsesQuinicData(assessmentData).updatedStatus}, userResponseData)
    setAssessmentData({...assessmentData, modules: getInvalidResponsesQuinicData(assessmentData).updatedStatus});
    // console.log(updatedModuleData);
    await dispatch(saveUserResponse({...updatedModuleData, status: "In Progress"} as AssessmentResponseType));
  }

  return (
    <div className='assessmentWQP pt-3 pe-3'>
      <div className='assessmentWQPModleHead d-flex justify-content-between pb-2'>
        <div className='subtitle-text'>{currentModule.name}</div>
        <div>
          <div className="customButton noBg">
            <button onClick={draftHandler} type='button'><div><SaveIcon /></div><div className='ps-3'>Draft</div></button>
          </div>
        </div>
      </div>
      <div className='px-3'>
        {
          currentModule.questions.map((data: AssessmentQuestionType, index: number) => {
            return (
              <div key={index} className='assessmentWQPList py-4'>
                <div className='d-flex'>
                  <div className='questionTitle'>
                    <p className='p-text'><span>Q.0{index + 1}</span></p>
                  </div>
                  <div className='flex-grow-1 ps-4'>
                    <div className='questionTitle'>
                      <p className='p-text'>{data.description}</p>
                    </div>
                    <div className='questionChoise'>
                      <div className='horizontalForm pt-2'>
                        <div className="d-flex flex-wrap w-100">
                          {data.options?.map((optionData, opIndex) => {
                            const optionList = optionData.values.map((opValueData) => opValueData.name);
                            return (
                              <div className={`col-md-5 formGroup ${opIndex % 2 ? "ms-md-5" : ""}`} key={opIndex}>
                                <label className='p-2'>{optionData.name}</label>
                                <Autocomplete
                                  fullWidth
                                  popupIcon={<DownArrowIcon />}
                                  options={optionList}
                                  value={optionData.answer ? optionData.answer : null}
                                  renderInput={(params) => <TextField {...params} placeholder={`Select ${optionData.name}`} />}
                                  onChange={(
                                    _event,
                                    newValue
                                  ) => {
                                    handleDropdownChange(newValue ? newValue : "", opIndex, index);
                                  }}
                                />
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default AssessmentWriterForm