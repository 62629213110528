export const NextArrowIcon = () => (
  <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 7.99997C10 7.71322 9.87995 7.4265 9.64036 7.20788L2.09719 0.328227C1.61736 -0.109409 0.839379 -0.109409 0.359734 0.328227C-0.119911 0.765686 -0.119911 1.4751 0.359734 1.91277L7.03435 7.99997L0.359967 14.0872C-0.119678 14.5249 -0.119678 15.2342 0.359967 15.6716C0.839612 16.1095 1.61759 16.1095 2.09743 15.6716L9.6406 8.79207C9.88023 8.57334 10 8.28662 10 7.99997Z" fill="white" />
  </svg>
)

export const PrevArrowIcon = () => (
  <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 8.00003C0 8.28678 0.120048 8.5735 0.359637 8.79212L7.90281 15.6718C8.38264 16.1094 9.16062 16.1094 9.64027 15.6718C10.1199 15.2343 10.1199 14.5249 9.64027 14.0872L2.96565 8.00003L9.64003 1.91279C10.1197 1.47515 10.1197 0.765811 9.64003 0.328387C9.16039 -0.109461 8.38241 -0.109461 7.90257 0.328387L0.359404 7.20793C0.119775 7.42666 0 7.71338 0 8.00003Z" fill="white" />
  </svg>
)

export const SaveIcon = () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.7583 5.24168L19.7583 0.24168C19.6805 0.164446 19.5881 0.103342 19.4866 0.0618712C19.385 0.0204007 19.2763 -0.000620149 19.1667 1.39286e-05H17.5V6.66668C17.5 7.1087 17.3244 7.53263 17.0118 7.84519C16.6993 8.15775 16.2754 8.33334 15.8333 8.33334H9.16666C8.72463 8.33334 8.30071 8.15775 7.98815 7.84519C7.67559 7.53263 7.5 7.1087 7.5 6.66668V1.39286e-05H2.5C1.83696 1.39286e-05 1.20107 0.263406 0.732233 0.732247C0.263392 1.20109 0 1.83697 0 2.50001V22.5C0 23.163 0.263392 23.7989 0.732233 24.2678C1.20107 24.7366 1.83696 25 2.5 25H4.16666V17.5C4.16666 16.837 4.43006 16.2011 4.8989 15.7322C5.36774 15.2634 6.00362 15 6.66666 15H18.3333C18.9964 15 19.6322 15.2634 20.1011 15.7322C20.5699 16.2011 20.8333 16.837 20.8333 17.5V25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V5.83334C25.0006 5.72367 24.9796 5.61495 24.9381 5.51342C24.8967 5.41189 24.8356 5.31954 24.7583 5.24168Z" fill="#6946C6" />
    <path d="M9 0H16V7H9V0Z" fill="#6946C6" />
    <path d="M18.1875 17H6.8125C6.59701 17 6.39035 17.0843 6.23798 17.2343C6.0856 17.3843 6 17.5878 6 17.8V25H19V17.8C19 17.5878 18.9144 17.3843 18.762 17.2343C18.6097 17.0843 18.403 17 18.1875 17Z" fill="#6946C6" />
  </svg>
)

export const DownArrowIcon = () => (
  <svg width="15" height="8" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.20005 8C7.45813 8 7.71617 7.90396 7.91293 7.71229L14.1046 1.67776C14.4985 1.29388 14.4985 0.671503 14.1046 0.287787C13.7109 -0.0959291 13.0724 -0.0959291 12.6785 0.287787L7.20005 5.62748L1.72153 0.287972C1.32766 -0.0957437 0.689256 -0.0957437 0.295575 0.287972C-0.0984881 0.671688 -0.0984881 1.29407 0.295575 1.67794L6.48717 7.71248C6.68402 7.90418 6.94207 8 7.20005 8Z" fill="#6946C6" />
  </svg>
)

export const LogoutIcon = () => (
  <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.6562 10.6849L18.2291 6.33765C18.12 6.2306 17.9906 6.14569 17.8482 6.08775C17.7058 6.02982 17.5531 6 17.399 6C17.2448 6 17.0921 6.02982 16.9497 6.08775C16.8073 6.14569 16.6779 6.2306 16.5689 6.33765C16.3487 6.55383 16.225 6.84703 16.225 7.15276C16.225 7.45848 16.3487 7.75168 16.5689 7.96786L18.992 10.3473H6.17391C5.86257 10.3473 5.56398 10.4687 5.34383 10.6849C5.12368 10.9011 5 11.1943 5 11.5C5 11.8057 5.12368 12.0989 5.34383 12.3151C5.56398 12.5313 5.86257 12.6527 6.17391 12.6527H18.992L16.5689 15.0321C16.3487 15.2483 16.225 15.5415 16.225 15.8473C16.225 16.153 16.3487 16.4462 16.5689 16.6624C16.789 16.8786 17.0876 17 17.399 17C17.7103 17 18.0089 16.8785 18.2291 16.6624L22.6562 12.3151C22.8763 12.0989 23 11.8057 23 11.5C23 11.1943 22.8763 10.9011 22.6562 10.6849Z" fill="white" />
    <path d="M13.8158 18.2414C13.5017 18.2414 13.2005 18.3667 12.9784 18.5898C12.7563 18.8129 12.6316 19.1155 12.6316 19.431V20.6207H2.36842V2.37931H12.6316V3.56897C12.6316 3.88448 12.7563 4.18708 12.9784 4.41018C13.2005 4.63328 13.5017 4.75862 13.8158 4.75862C14.1299 4.75862 14.4311 4.63328 14.6532 4.41018C14.8752 4.18708 15 3.88448 15 3.56897V1.98276C15 1.72238 14.9489 1.46455 14.8498 1.22399C14.7506 0.98343 14.6052 0.764853 14.4219 0.580737C14.2386 0.39662 14.0211 0.250571 13.7816 0.150929C13.5422 0.0512856 13.2855 0 13.0263 0H1.97368C1.45023 0 0.948216 0.208897 0.578079 0.580737C0.207941 0.952576 0 1.4569 0 1.98276V21.0172C0 21.5431 0.207941 22.0474 0.578079 22.4193C0.948216 22.7911 1.45023 23 1.97368 23H13.0263C13.2855 23 13.5422 22.9487 13.7816 22.8491C14.0211 22.7494 14.2386 22.6034 14.4219 22.4193C14.6052 22.2351 14.7506 22.0166 14.8498 21.776C14.9489 21.5355 15 21.2776 15 21.0172V19.431C15 19.1155 14.8752 18.8129 14.6532 18.5898C14.4311 18.3667 14.1299 18.2414 13.8158 18.2414Z" fill="white" />
  </svg>
)

export const UserIcon = (props: any) => (
  <svg {...props} width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.7782 12.2825C17.5801 11.2478 16.1541 10.4818 14.5982 10.0181C16.2646 9.02689 17.3594 7.36799 17.3594 5.49219C17.3594 2.4638 14.5066 0 11 0C7.49345 0 4.64062 2.4638 4.64062 5.49219C4.64062 7.36799 5.73543 9.02689 7.40188 10.0181C5.84598 10.4818 4.41994 11.2478 3.22184 12.2825C1.14421 14.0768 0 16.4625 0 19H1.71875C1.71875 14.5802 5.88229 10.9844 11 10.9844C16.1177 10.9844 20.2812 14.5802 20.2812 19H22C22 16.4625 20.8558 14.0768 18.7782 12.2825ZM11 9.5C8.44117 9.5 6.35938 7.70212 6.35938 5.49219C6.35938 3.28225 8.44117 1.48438 11 1.48438C13.5588 1.48438 15.6406 3.28225 15.6406 5.49219C15.6406 7.70212 13.5588 9.5 11 9.5Z" fill="white" />
  </svg>
)

export const EditIcon = () => (
  <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.9154 4.06006L1.5197 15.4566C1.46237 15.5141 1.42097 15.5867 1.40126 15.6647L0.138157 20.7346C0.100373 20.8872 0.14522 21.0495 0.256598 21.1609C0.340871 21.2451 0.455699 21.2918 0.573155 21.2918C0.609131 21.2918 0.645929 21.2873 0.68174 21.2783L5.75158 20.015C5.83059 19.9953 5.90238 19.9541 5.95971 19.8968L17.3564 8.50105L12.9154 4.06006Z" fill="#6946C6" />
    <path d="M20.1802 2.50638L18.9117 1.23785C18.0639 0.390032 16.5863 0.390853 15.7394 1.23785L14.1855 2.79172L18.6264 7.23255L20.1802 5.67868C20.6037 5.25535 20.837 4.69188 20.837 4.09261C20.837 3.49334 20.6037 2.92988 20.1802 2.50638Z" fill="#6946C6" />
  </svg>
)

export const CancelIcon = () => (
  <svg width="18" height="18" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.32453 4.50007L8.82895 0.995468C9.05702 0.767509 9.05702 0.398929 8.82895 0.17097C8.601 -0.0569899 8.23243 -0.0569899 8.00447 0.17097L4.49995 3.67557L0.995528 0.17097C0.767466 -0.0569899 0.399001 -0.0569899 0.171046 0.17097C-0.0570154 0.398929 -0.0570154 0.767509 0.171046 0.995468L3.67547 4.50007L0.171046 8.00467C-0.0570154 8.23263 -0.0570154 8.6012 0.171046 8.82916C0.28465 8.94288 0.434022 9 0.583287 9C0.732552 9 0.881817 8.94288 0.995528 8.82916L4.49995 5.32456L8.00447 8.82916C8.11818 8.94288 8.26745 9 8.41671 9C8.56598 9 8.71524 8.94288 8.82895 8.82916C9.05702 8.6012 9.05702 8.23263 8.82895 8.00467L5.32453 4.50007Z" fill="#DADADA" />
  </svg>
)

export const AddIcon = () => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13 0C10.4288 0 7.91543 0.762436 5.77759 2.19089C3.63975 3.61935 1.97351 5.64967 0.989571 8.02511C0.00563276 10.4005 -0.25181 13.0144 0.249797 15.5362C0.751404 18.0579 1.98953 20.3743 3.80761 22.1924C5.6257 24.0105 7.94207 25.2486 10.4638 25.7502C12.9856 26.2518 15.5994 25.9944 17.9749 25.0104C20.3503 24.0265 22.3806 22.3602 23.8091 20.2224C25.2375 18.0846 26 15.5711 26 13C25.9959 9.55343 24.625 6.24919 22.1879 3.8121C19.7508 1.37501 16.4466 0.00406601 13 0ZM18.9091 14.1818H14.1818V18.9091C14.1818 19.2225 14.0573 19.5231 13.8357 19.7447C13.614 19.9664 13.3134 20.0909 13 20.0909C12.6866 20.0909 12.386 19.9664 12.1643 19.7447C11.9427 19.5231 11.8182 19.2225 11.8182 18.9091V14.1818H7.09091C6.77747 14.1818 6.47687 14.0573 6.25524 13.8357C6.0336 13.614 5.90909 13.3134 5.90909 13C5.90909 12.6866 6.0336 12.386 6.25524 12.1643C6.47687 11.9427 6.77747 11.8182 7.09091 11.8182H11.8182V7.0909C11.8182 6.77747 11.9427 6.47687 12.1643 6.25523C12.386 6.0336 12.6866 5.90909 13 5.90909C13.3134 5.90909 13.614 6.0336 13.8357 6.25523C14.0573 6.47687 14.1818 6.77747 14.1818 7.0909V11.8182H18.9091C19.2225 11.8182 19.5231 11.9427 19.7448 12.1643C19.9664 12.386 20.0909 12.6866 20.0909 13C20.0909 13.3134 19.9664 13.614 19.7448 13.8357C19.5231 14.0573 19.2225 14.1818 18.9091 14.1818Z" fill="#6946C6" />
  </svg>
)

export const DragIcon = () => (
  <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="1.33333" cy="8.71452" rx="1.31429" ry="1.33333" transform="rotate(-90 1.33333 8.71452)" fill="#CACACA" fillOpacity="0.8" />
    <ellipse cx="1.33333" cy="2.14323" rx="1.31429" ry="1.33333" transform="rotate(-90 1.33333 2.14323)" fill="#CACACA" fillOpacity="0.8" />
    <ellipse cx="8.00033" cy="8.71452" rx="1.31429" ry="1.33333" transform="rotate(-90 8.00033 8.71452)" fill="#CACACA" fillOpacity="0.8" />
    <ellipse cx="8.00033" cy="2.14323" rx="1.31429" ry="1.33333" transform="rotate(-90 8.00033 2.14323)" fill="#CACACA" fillOpacity="0.8" />
    <ellipse cx="14.6663" cy="8.71452" rx="1.31429" ry="1.33333" transform="rotate(-90 14.6663 8.71452)" fill="#CACACA" fillOpacity="0.8" />
    <ellipse cx="14.6663" cy="2.14323" rx="1.31429" ry="1.33333" transform="rotate(-90 14.6663 2.14323)" fill="#CACACA" fillOpacity="0.8" />
  </svg>
)

export const AdminIcon = () => (
  <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.3663 15.3683H1.98922C1.00323 15.3683 0.301026 14.4078 0.603008 13.4691L1.80002 9.72886C2.18569 8.52821 3.49913 7.90605 4.67068 8.36084C5.44928 8.66282 6.44983 8.89932 7.67595 8.89932C8.90207 8.89932 9.90262 8.66282 10.6812 8.36084C10.9286 8.26625 11.187 8.21531 11.438 8.21167L11.4889 8.24078C11.4853 8.29535 11.4853 8.34629 11.4853 8.40086C11.4853 8.45544 11.4853 8.51001 11.4889 8.56095L11.4307 8.5937C10.8449 8.93206 10.6412 9.6852 10.9796 10.271L11.4089 11.0132C11.6272 11.3916 12.0347 11.6281 12.4749 11.6281C12.6896 11.6281 12.9043 11.5699 13.0898 11.4644L13.148 11.4316C13.239 11.4898 13.3299 11.5408 13.4245 11.5917V11.6572C13.4245 12.3048 13.9303 12.8396 14.567 12.8833L14.7525 13.4618C15.0545 14.4078 14.356 15.3683 13.3663 15.3683Z" fill="#6946C6" />
    <path d="M7.67984 7.50227C9.75154 7.50227 11.431 5.82283 11.431 3.75113C11.431 1.67944 9.75154 0 7.67984 0C5.60815 0 3.92871 1.67944 3.92871 3.75113C3.92871 5.82283 5.60815 7.50227 7.67984 7.50227Z" fill="#9977F3" />
    <path d="M14.6528 4.64258H15.5114C15.7879 4.64258 16.0135 4.86816 16.0135 5.14467V6.58545H14.1543V5.14467C14.1543 4.86452 14.3762 4.64258 14.6528 4.64258Z" fill="#8B64F1" />
    <path d="M14.155 10.2239H16.0142V11.661C16.0142 11.9375 15.7886 12.1631 15.5121 12.1631H14.6535C14.3769 12.1631 14.1514 11.9375 14.1514 11.661L14.155 10.2239Z" fill="#8B64F1" />
    <path d="M11.6118 6.89462L12.0411 6.1524C12.1794 5.91227 12.485 5.83222 12.7251 5.97048L13.9731 6.69087L13.0416 8.30266L11.7937 7.58227C11.5572 7.44037 11.4735 7.13475 11.6118 6.89462Z" fill="#8B64F1" />
    <path d="M16.1963 10.1181L17.1277 8.50635L18.3757 9.22674C18.6158 9.365 18.6958 9.67062 18.5576 9.91075L18.1283 10.653C17.99 10.8931 17.6844 10.9731 17.4442 10.8349L16.1963 10.1181Z" fill="#8B64F1" />
    <path d="M12.0411 10.653L11.6118 9.91075C11.4735 9.67062 11.5572 9.365 11.7937 9.22674L13.0416 8.50635L13.9731 10.1181L12.7251 10.8385C12.485 10.9768 12.1794 10.8931 12.0411 10.653Z" fill="#8B64F1" />
    <path d="M17.1277 8.29918L16.1963 6.68739L17.4442 5.967C17.6844 5.82874 17.99 5.91242 18.1283 6.14892L18.5576 6.89114C18.6958 7.13127 18.6121 7.43689 18.3757 7.57515L17.1277 8.29918Z" fill="#8B64F1" />
    <path d="M15.0835 5.53003C13.4972 5.53003 12.2129 6.81437 12.2129 8.40068C12.2129 9.987 13.4972 11.2713 15.0835 11.2713C16.6699 11.2713 17.9542 9.987 17.9542 8.40068C17.9542 6.81437 16.6699 5.53003 15.0835 5.53003ZM15.0835 9.65227C14.3923 9.65227 13.8356 9.09197 13.8356 8.40432C13.8356 7.71667 14.3959 7.15637 15.0835 7.15637C15.7712 7.15637 16.3315 7.71667 16.3315 8.40432C16.3315 9.09197 15.7748 9.65227 15.0835 9.65227Z" fill="#9977F3" />
  </svg>
)

export const CrownIcon = () => (
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.2505 11.5079C3.31716 10.1386 2.38384 8.76939 1.4505 7.40011C1.24358 7.09659 1.50961 6.69509 1.86977 6.76731C2.83192 6.9602 3.79402 7.15308 4.75618 7.34597C5.07297 7.40949 5.37806 7.19383 5.42388 6.87402L5.92536 3.37513C5.97836 3.00538 6.3904 2.80855 6.71133 2.99969L9.74818 4.80834C10.0257 4.97368 10.3852 4.87184 10.5349 4.58554C10.9895 3.71588 11.444 2.84627 11.8986 1.97662C12.0688 1.65107 12.5483 1.69645 12.6544 2.04813C13.133 3.63462 13.6116 5.22108 14.0902 6.80758L4.2505 11.5079Z" fill="#FFB743" />
    <path d="M14.7526 9.51254L5.93895 13.7227C5.65563 13.8581 5.31623 13.7381 5.1809 13.4548L4.64258 12.3279L14.4823 7.62755L15.0206 8.75447C15.1559 9.03781 15.0359 9.3772 14.7526 9.51254Z" fill="#FFB743" />
  </svg>
)

export const RemoveIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 6.00032C0 2.68632 2.6863 0.000244141 5.9999 0.000244141C9.31368 0.000244141 12 2.68635 12 6.00032C12 9.31383 9.31368 12.0001 5.9999 12.0001C2.6863 12.0001 0 9.31383 0 6.00032Z" fill="#E04F5F" />
    <path d="M9.27669 4.98926H2.72392C2.62776 4.98926 2.5498 5.06726 2.5498 5.1634V6.83674C2.5498 6.9329 2.62778 7.01086 2.72392 7.01086H9.27669C9.37285 7.01086 9.4508 6.93288 9.4508 6.83674V5.1634C9.4508 5.06726 9.37282 4.98926 9.27669 4.98926Z" fill="white" />
  </svg>
)

export const DeleteIcon = () => (
  <svg width="19" height="25" viewBox="0 0 19 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.26479 23.3022C2.29242 23.9622 2.83544 24.4829 3.49582 24.4829H14.9983C15.6587 24.4829 16.2017 23.9622 16.2293 23.3022L17.0508 5.96167H1.44336L2.26479 23.3022ZM11.8491 10.2688C11.8491 9.99246 12.0731 9.76835 12.3495 9.76835H13.15C13.4262 9.76835 13.6504 9.9924 13.6504 10.2688V20.1758C13.6504 20.4522 13.4264 20.6763 13.15 20.6763H12.3495C12.0732 20.6763 11.8491 20.4523 11.8491 20.1758V10.2688ZM8.34642 10.2688C8.34642 9.99246 8.57047 9.76835 8.84683 9.76835H9.64728C9.92353 9.76835 10.1477 9.9924 10.1477 10.2688V20.1758C10.1477 20.4522 9.92371 20.6763 9.64728 20.6763H8.84683C8.57053 20.6763 8.34642 20.4523 8.34642 20.1758V10.2688ZM4.84368 10.2688C4.84368 9.99246 5.06772 9.76835 5.34408 9.76835H6.1446C6.4209 9.76835 6.64501 9.9924 6.64501 10.2688V20.1758C6.64501 20.4522 6.42096 20.6763 6.1446 20.6763H5.34408C5.06778 20.6763 4.84368 20.4523 4.84368 20.1758V10.2688Z" fill="#6946C6" />
    <path d="M17.5976 1.26124H12.2911V0.258027C12.2911 0.115557 12.1756 0 12.0331 0H6.46073C6.31826 0 6.20276 0.115557 6.20276 0.258027V1.26118H0.896227C0.46918 1.26118 0.123047 1.60738 0.123047 2.03442V4.46347H18.3708V2.03448C18.3708 1.60744 18.0246 1.26124 17.5976 1.26124Z" fill="#6946C6" />
  </svg>
);

export const DuplicateIcon = () => (
  <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.0043 20.3172C14.8564 20.7354 14.5829 21.0976 14.221 21.3542C13.8592 21.6108 13.4269 21.7492 12.9833 21.7505H2.95024C2.38056 21.7488 1.8347 21.5217 1.43188 21.1189C1.02905 20.7161 0.801994 20.1702 0.800293 19.6005V5.26754C0.80154 4.82397 0.939953 4.39165 1.19656 4.02983C1.45316 3.66802 1.81539 3.39444 2.23359 3.24658V16.7339C2.23359 17.6843 2.61111 18.5957 3.2831 19.2677C3.95509 19.9397 4.86651 20.3172 5.81684 20.3172H15.0043Z" fill="#6946C6" />
    <path d="M12.9834 4.55041C12.9834 4.74048 13.0589 4.92276 13.1933 5.05716C13.3277 5.19155 13.51 5.26706 13.7 5.26706H17.57C17.509 5.18197 17.4422 5.10127 17.37 5.02555L13.2278 0.883311C13.1511 0.811253 13.0694 0.744679 12.9834 0.684082V4.55041Z" fill="#6946C6" />
    <path d="M13.7001 6.70034C13.1299 6.70034 12.583 6.47383 12.1798 6.07063C11.7767 5.66744 11.5501 5.12059 11.5501 4.55039V0.250488H5.81694C5.24674 0.250488 4.69989 0.477 4.2967 0.880194C3.8935 1.28339 3.66699 1.83024 3.66699 2.40044V16.7334C3.66699 17.3036 3.8935 17.8505 4.2967 18.2537C4.69989 18.6569 5.24674 18.8834 5.81694 18.8834H15.85C16.4202 18.8834 16.9671 18.6569 17.3703 18.2537C17.7735 17.8505 18 17.3036 18 16.7334V6.70034H13.7001Z" fill="#6946C6" />
  </svg>
)