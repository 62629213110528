import React, { useEffect, useState } from 'react';
/** Import Of reusable components */
import SearchComponent from '../../../reusableComponents/searchComponent';
import { config } from '../../../../config/clientConfig';
import { AddIcon, SaveIcon } from '../../../reusableComponents/svgIcons';
import EmptyScreenVector from "../../../../assets/images/avatars/assessmentEmptySvg.svg";

const searchHandler = (value: string) => {
    console.log(value);
}
const ProjectEmpty = () => {
    const [isProjectCreationActive, setIsProjectCreationActive] = useState<boolean>(config.negative);  
    const searchHandler = (value: string) => {
        console.log(value);
    }
    return (
        <section className='assessmentManagementSection'>
                <div>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div>
                            <div className='assessmentTopSticky d-flex flex-wrap justify-content-between align-items-center px-3 pt-4 pb-2'>
                                <SearchComponent searchHandler={searchHandler} />
                            </div>
                        </div>
                        <div className="customButton noBg saveButton">
                        <button onClick={() => setIsProjectCreationActive(config.positive)}><div><AddIcon /></div><div className='ps-3'>Create Project</div></button>
                        </div>
                    </div>
                    
                        <div className='emptyAssessmentScreen px-3 pt-2 pb-5'>
                            <div>
                                <img src={EmptyScreenVector} alt="EmptyScreenVector" />
                                <p className='p-text text-center'>Project is empty, <br />
                                    <span>Please click on below button to create project</span></p>
                                <div className="customButton mt-3">
                            <button onClick={() => setIsProjectCreationActive(config.positive)}><div>Create Project</div></button>
                                </div>
                            </div>
                        </div>
                    

                </div>
            
        </section >
    )
}

export default ProjectEmpty