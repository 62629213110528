import { Breadcrumbs } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { AssessmentDetailsObjTypeWithoutID, AssessmentDetailsObjTypeWithtDis, AssessmentModuleType } from '../../../../store/actions/assessmentManagement/assessmentManagementInterface';
import { isObjectEmpty } from '../../../../utils/objectUtils';
import { AddIcon, NextArrowIcon, PrevArrowIcon, SaveIcon } from '../../../reusableComponents/svgIcons';
import ModulesCreationSidebar from './modulesCreation/modulesCreationSidebar';
/** Imports of Rect Beautiful DND for the draggable component */
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import SettingCreation from './settingsCreation/settingsCreation';
import QuestionsCreation from './questionsCreation/questionsCreation';
import InstructionCreation from './instructionCreation/instructionCreation';
import { createNewdAssessments, getCreatedAssessments, updatedAssessments } from '../../../../store/actions/assessmentManagement/assessmentManagementAction';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/reducers/rootReducer';
import { config } from '../../../../config/clientConfig';

interface Props {
  setIsAssessmentCreationActive: React.Dispatch<React.SetStateAction<boolean>>
}

const initialState: AssessmentDetailsObjTypeWithoutID = {
  title: "",
  assessmentType: "",
  reportType: "",
  publishedDate: "",
  isPublished: config.negative,
  endDate: "",
  project: "",
  department: "",
  score: "",
  gaprank: "",
  description: "",
  modules: [],
  creator: ""
};

const initialStateAssessmentSettings: AssessmentDetailsObjTypeWithtDis = {
  title: "",
  assessmentType: "",
  reportType: "",
  publishedDate: "",
  endDate: "",
  project: "",
  department: "",
  score: "",
  gaprank: "",
  description: "",
  creator: ""
};

const AssessmentCreation = ({ setIsAssessmentCreationActive }: Props) => {
  const dispatch = useDispatch();
  const editableData = useSelector((state: RootState) => state.myAssessments.editableAssessment);
  const [assessmentDetails, setAssessmentDetails] = useState<AssessmentDetailsObjTypeWithoutID>(!isObjectEmpty(editableData) ? initialState : editableData);
  // state for store current module details
  const [currentModule, setCurrentModule] = React.useState({});
  const [assessmentSettings, setAssessmentSettings] = React.useState<AssessmentDetailsObjTypeWithtDis>(initialStateAssessmentSettings);
  const [assessmentInstruction, setAssessmentInstruction] = React.useState<any>(!isObjectEmpty(editableData) ?  { name: "Introduction", description: "", questions: [] } : editableData.modules.slice(0, 1)[0]);
  const [currentModuleIndex, setCurrentModuleIndex] = React.useState<number>(0);
  const storeInitialAssesmentDetails = () => {
    const date = new Date().toISOString();
    setAssessmentDetails({ ...assessmentDetails, modules: [assessmentInstruction, ...assessmentDetails.modules] })
    console.log(date)
  }
  useEffect(() => {
    console.log("assessmentDetails", assessmentDetails);
    let isExist = config.negative;
    assessmentDetails.modules.map((data) => {
      if (data.name === "Introduction") { isExist = config.positive }
    });
    !isExist && storeInitialAssesmentDetails();
    return () => { isExist = config.negative };
  }, []);

  
  // useEffect(() => {
  //   setAssessmentSettings
  // }, [editableData]);

  // useEffect(() => {
  //   if (isObjectEmpty(assessmentDetails)) {
  //     assessmentDetails.modules.map((data, index: number) => {
  //       if (index === currentModuleIndex) {
  //         setCurrentModule(data);
  //       }
  //     });
  //   }
  // }, [currentModuleIndex]);

  const nextModuleHandler = () => {
    setCurrentModuleIndex(currentModuleIndex + 1);
  }
  const prevModuleHandler = () => {
    setCurrentModuleIndex(currentModuleIndex - 1);
  }
  const addNewModuleHandler = () => {
    const initialModuleState: AssessmentModuleType = {
      name: "Module" + (assessmentDetails.modules.length),
      questions: []
    }
    console.log([...assessmentDetails.modules, initialModuleState]);
    setAssessmentDetails({ ...assessmentDetails, modules: [...assessmentDetails.modules, initialModuleState] })
  }

  /** This funtion coming from the React Beautify DND package.
    * After the Drag event we're changing the order of the form fields using splice.
  */
  const handleDragEnd = (result: DropResult) => {
    //if no destination exits(cancel event), exit this function
    if (!result.destination) return;
    if ((result.destination.index == result.source.index)) return;
    const items = Array.from(assessmentDetails.modules);
    const [reorderedItem] = items.splice(result.source.index, 1);
    // console.log("reorderedItem", result.source.index, result.destination.index, currentModuleIndex);
    items.splice(result.destination.index, 0, reorderedItem);
    // setCurrentModuleIndex(result.source.index == (currentModuleIndex + 1) ? result.destination.index + 1 : result.destination.index == (currentModuleIndex + 1) ? result.source.index + 1 : currentModuleIndex);
    setAssessmentDetails({ ...assessmentDetails, modules: items });
  };

  const saveHandler = async () => {
    const userId = localStorage.getItem('userEmail');
    const updatedModule = assessmentDetails.modules.map((data, index) => index === 0 ? assessmentInstruction : data);
    const finalPostData = {
      ...assessmentDetails,
      isPublished: config.negative,
      modules: updatedModule,
      creator: userId ? userId : ""
    };
    console.log("updatedModule", finalPostData);
    // const sd = {...finalPostData}
    !isObjectEmpty(editableData) ? await dispatch(createNewdAssessments(finalPostData))
    : await dispatch(updatedAssessments(editableData.assessmentId ,finalPostData));
    await dispatch(getCreatedAssessments());
    setIsAssessmentCreationActive(config.negative);
    // console.log(finalPostData);
  }

  return (
    <section className='assessmentCreationSection assessmentWP'>
      <div className='d-flex align-items-center mb-4'>
        <div className='assessmentWPBreadcrumbs flex-grow-1'>
          <Breadcrumbs separator={<NextArrowIcon />} aria-label="breadcrumb">
            <button onClick={() => setIsAssessmentCreationActive(config.negative)}>Assessments Management</button>
            <div>Create Assessment</div>
          </Breadcrumbs>
        </div>
        <div className="customButton noBg saveButton">
          <button onClick={saveHandler}><div><SaveIcon /></div><div className='ps-3'>Save</div></button>
        </div>
      </div>
      <div className='assessmentWPMain d-flex mb-5'>
        <div className='ACModuleSection'>
          <DragDropContext onDragEnd={handleDragEnd}>
            <div className='modulesLeftNav px-3'>
              <div className={`modulesList py-3 d-flex align-items-center w-100 text-start ${currentModuleIndex === 0 ? "active" : ""}`}>
                <div>
                  <div className='moduleNo'>00</div>
                </div>
                <button onClick={() => setCurrentModuleIndex(0)} className='p-text ps-2 flex-grow-1 cursor-pointer'>Assessment Settings</button>
              </div>
              <Droppable droppableId="schemaDragForm">
                {(provided): JSX.Element => (
                  <div key={currentModuleIndex} {...provided.droppableProps} ref={provided.innerRef}>
                    {assessmentDetails.modules.map((data: AssessmentModuleType, index: number) => (
                      index === 0 ?
                        <div key={index} className={`modulesList py-3 d-flex align-items-center w-100 text-start ${index + 1 === currentModuleIndex ? "active" : ""}`}>
                          <div>
                            <div className='moduleNo'>{index > 9 ? index + 1 : "0" + (index + 1)}</div>
                          </div>
                          <button onClick={() => setCurrentModuleIndex(index + 1)} className='p-text ps-2 flex-grow-1 cursor-pointer'>{data.name}</button>
                        </div> :
                        <Draggable key={index} draggableId={index.toString()}
                          isDragDisabled={index === 0}
                          index={index}>
                          {(provided, snapshot): JSX.Element => (
                            <div {...provided.draggableProps}
                              ref={provided.innerRef}
                              tabIndex={-1}
                              className={
                                `modulesListHead ${snapshot.isDragging ? "selected" : "not-selected"}`
                              }>
                              <ModulesCreationSidebar
                                provided={provided}
                                assessmentData={data}
                                moduleIndex={index}
                                currentModuleIndex={currentModuleIndex}
                                setAssessmentDetails={setAssessmentDetails}
                                setCurrentModuleIndex={setCurrentModuleIndex} />
                            </div>
                          )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>

              <button onClick={() => addNewModuleHandler()} className={`modulesList py-3 d-flex align-items-center w-100 text-start`}>
                <div>
                  <div className='moduleNo addModule'>
                    <AddIcon />
                  </div>
                </div>
                <div className='p-text ps-2 flex-grow-1'>Add Module</div>
              </button>
            </div>
          </DragDropContext>
        </div>
        <div className='modulesRightQu'>
          <div className='pb-5 d-flex flex-column h-100 justify-content-between'>
            {currentModuleIndex === 0 ? <SettingCreation assessmentSettings={assessmentDetails} setAssessmentSettings={setAssessmentDetails} />
              : currentModuleIndex === 1 ? <InstructionCreation assessmentInstruction={assessmentInstruction} setAssessmentInstruction={setAssessmentInstruction} />
                : <QuestionsCreation currentModuleIndex={currentModuleIndex} assessmentDetails={assessmentDetails} setAssessmentDetails={setAssessmentDetails} />
            }
            <div className='assesmentWPActions d-flex justify-content-end align-items-center px-4 pt-4'>
              <div>
                <div className="customButton">
                  <button
                    className={`${currentModuleIndex === 0 ? "disabledBtn" : ""}`}
                    onClick={prevModuleHandler}
                    disabled={currentModuleIndex === 0}>
                    <div className='pe-3'><PrevArrowIcon /></div>
                    <div>Prev</div>
                  </button>
                </div>
              </div>
              <div className='ps-4'>
                <div className="customButton">
                  <button
                    className={`${currentModuleIndex === assessmentDetails.modules?.length ? "disabledBtn" : ""}`}
                    onClick={nextModuleHandler}
                    disabled={currentModuleIndex === assessmentDetails.modules?.length}>
                    <div>Next</div>
                    <div className='ps-3'><NextArrowIcon /></div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AssessmentCreation