/**Imports of React libraries */
import React, { useEffect, useState } from 'react';
/** Import Of reusable components */
import Loader from '../../loader/loader';
import FilterComponent from '../../reusableComponents/filterComponent';
import SearchComponent from '../../reusableComponents/searchComponent';
import AssessmentList from './assessmentList/assessmentList';
import AssessmentWriter from './assessmentWriter/assessmentWriterIndex';
/**Import of Config files */
import { getMyAssessments } from '../../../store/actions/myAssessments/myAssessmentsAction';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers/rootReducer';
import { config } from '../../../config/clientConfig';
import { AssessmentResponseWithAType } from '../../../store/actions/assessmentManagement/assessmentResponseType';

const MyAssessments = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsDataLoading] = useState<boolean>(config.positive);
  const [isAssessmentActive, setIsAssessmentActive] = useState<boolean>(config.negative);
  const [selectedAssessment, setSelectedAssessment] = useState<AssessmentResponseWithAType>({} as AssessmentResponseWithAType);
  const assignedAssessments: AssessmentResponseWithAType[] = useSelector(
    (state: RootState) => state.myAssessments.assignedAssessments
  );
  const [searchedAssessment, setSearchedAssessment] = useState([] as any[]);// state for store if user responsed already
  // const [userResponseData, setUserResponseData] = React.useState<AssessmentResponseType>({} as AssessmentResponseType);

  // useEffect(() => {
  //   if(isObjectEmpty(selectedAssessment)){
  //     const {assessment, ...responseDetails} = selectedAssessment;
  //     setUserResponseData(responseDetails);
  //   }
  // }, [selectedAssessment]);

  const initialGet = async () => {
    console.log("first")
    setIsDataLoading(config.positive);
    await dispatch(getMyAssessments());
    setIsDataLoading(config.negative);
  }

  useEffect(() => {
    let ignore = config.negative;
    !ignore && !isAssessmentActive && initialGet();
    return () => { ignore = config.positive }
  }, [isAssessmentActive])

  const searchHandler = (value: string) => {
    const searchedData: any[] = search(assignedAssessments, value);
    setSearchedAssessment(searchedData);
  }

  /* here we create a function 
//     we filter the items
// use array property .some() to return an item even if other requirements didn't match
    */
  const search = (items: any, q: string) => {
    const searchParam = ["title", "assessmentType"];
    const searchedData: any[] = [];
    items.map((data: any) => {
      const matchedData = data.assessment.filter((item: any) => {
        return searchParam.some((newItem) => {
          return (
            item[newItem]
              .toString()
              .toLowerCase()
              .indexOf(q.toLowerCase()) > -1
          );
        });
      });
      matchedData.length > 0 && searchedData.push(data);
    })
    return searchedData;
  }

  if (!isAssessmentActive) {
    return (
      <div className='assessmentLandingSection'>
        {isLoading ? <Loader /> :
          <React.Fragment>
            <div className='assessmentTopSticky d-flex flex-wrap justify-content-between align-items-center px-3 pt-4 pb-2'>
              <SearchComponent searchHandler={searchHandler} />
              {/* <FilterComponent /> */}
            </div>
            <div className='px-3 pt-2'>
              <AssessmentList
                listOfAssessments={searchedAssessment.length > 0 ? searchedAssessment : assignedAssessments}
                setIsAssessmentActive={setIsAssessmentActive}
                setSelectedAssessment={setSelectedAssessment}
              />
            </div>
          </React.Fragment>
        }
      </div>
    )
  } else {
    return (
      <>
        <div className='px-3 pt-4'>
          <AssessmentWriter setIsAssessmentActive={setIsAssessmentActive} selectedAssessment={selectedAssessment} />
        </div>
      </>
    );
  }
};

export default MyAssessments;