import { AxiosError } from 'axios';
import { Dispatch } from 'redux';
import { config } from '../../../config/clientConfig';
import axiosInstance from '../../../utils/leoAxios';
import { STORE_EDITABLE_ASSESSMENTS, STORE_MY_ASSESSMENTS, STORE_WRITING_ASSESSMENT } from "../actiontypes"
import { AssessmentDetailsObjType } from '../assessmentManagement/assessmentManagementInterface';
import { AssessmentResponseType } from '../assessmentManagement/assessmentResponseType';

/** Get List form assigned Assessments */
export const getMyAssessments = () => async (dispatch: Dispatch) => {
    const userEmail = localStorage.getItem('userEmail');
    // const accessToken
    const response = await axiosInstance({
        method: "GET",
        url: "/api/User/" + userEmail
    }).then((res) => {
        dispatch({
            payload: res.data || [],
            type: STORE_MY_ASSESSMENTS,
        });
        return res.data;
    }).catch((err: AxiosError) => err.response?.data);
    return response;
}


/** Get List Assessment details
 * 
 */
export const getAssessmentsDetails = (assessmendID: string) => async (dispatch: Dispatch) => {
    const response = await axiosInstance({
        method: "GET",
        url: "/api/Assessment/" + assessmendID
    }).then((res) => {
        dispatch({
            payload: res.data,
            type: STORE_WRITING_ASSESSMENT,
        });
        return res.data;
    }).catch((err: AxiosError) => err.response?.data);
    return response;
}

/** Store editable data */
export const storeEditableData = (aData: AssessmentDetailsObjType) => (dispatch: Dispatch) => {
    dispatch({
        payload: aData || [],
        type: STORE_EDITABLE_ASSESSMENTS,
    });
}


/** Save the user response */
export const saveUserResponse = (userResponseData: AssessmentResponseType) => async (dispatch: Dispatch) => {
    const response = await axiosInstance({
        method: "PUT",
        url: "/api/User/" + userResponseData.userResponseId,
        data: userResponseData
    }).then((res) => {
        alert("Responese Saved Successfully 😀");
        return res;
    }).catch((err: AxiosError) => {
        alert("There were some issue while saving the responese 😕");
        return err.response?.data
    });
    return response;
}

/** publish the user response */
export const publishAssessment = (aData: AssessmentDetailsObjType) => async (dispatch: Dispatch) => {
    const response = await axiosInstance({
        method: "POST",
        url: "/api/Assessment/" + aData.assessmentId + "/publish",
        data: JSON.stringify(aData)
    }).then((res) => {
        alert("Responese Saved Successfully 😀");
        return res;
    }).catch((err: AxiosError) => {
        alert("There were some issue while saving the responese 😕");
        return err.response?.data
    });
    return response;
}