import CircularProgress from '@mui/material/CircularProgress';

const Loader = (): JSX.Element => {
  return (
    <div className='fullPageLoader'>
        <CircularProgress />
    </div>
  )
}

export default Loader