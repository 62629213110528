import React, { useState } from 'react'

const SearchIcon = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23.9642 3.0381C19.9325 -1.00475 13.3868 -1.01387 9.34397 3.01782C5.44304 6.90793 5.27799 13.1736 8.96862 17.2638L8.17912 18.0533C7.54126 17.4153 6.50702 17.4152 5.86909 18.0531C5.86902 18.0531 5.86896 18.0532 5.86889 18.0533L0.478523 23.4436C-0.159405 24.0815 -0.159542 25.1157 0.478318 25.7537C0.478386 25.7537 0.478455 25.7538 0.478523 25.7539L1.24842 26.5238C1.88628 27.1614 2.92025 27.1614 3.55811 26.5238L8.94848 21.1334C9.5864 20.4955 9.58654 19.4613 8.94868 18.8234C8.94861 18.8233 8.94854 18.8232 8.94848 18.8232L9.73797 18.0337C13.974 21.8618 20.5114 21.5311 24.3395 17.2951C28.0372 13.2034 27.8721 6.92957 23.9642 3.0381ZM8.17912 20.363L2.78876 25.7533C2.57614 25.9659 2.23148 25.9659 2.01886 25.7533L1.24896 24.9829C1.03675 24.7703 1.03675 24.4261 1.24896 24.2135L6.63933 18.8232C6.85467 18.6173 7.19388 18.6173 7.40923 18.8232L8.17912 19.5936C8.39133 19.8062 8.39133 20.1504 8.17912 20.363ZM23.1948 16.8984C19.58 20.5131 13.7193 20.513 10.1046 16.8982C6.48994 13.2834 6.49001 7.42267 10.1048 3.80799C13.7196 0.193386 19.5801 0.193386 23.1948 3.80799C26.8037 7.42526 26.8037 13.2812 23.1948 16.8984Z" fill="white" />
    <path d="M22.4242 4.57715L21.6543 5.34704C24.4138 8.11328 24.4138 12.5911 21.6543 15.3573L22.4242 16.1272C25.6091 12.9359 25.6091 7.76849 22.4242 4.57715Z" fill="white" />
  </svg>
)

interface Props {
  searchHandler: (value: string) => void
}

const SearchComponent = ({searchHandler}: Props) => {
  const [value, setValue] = useState("");
  return (
    <form className='searchComponent d-flex' onSubmit={(e) => {e.preventDefault(); searchHandler(value)}}>
      <div>
        <input type="text" name="search" value={value} placeholder='Search Assessment' onChange={(e) => setValue(e.target.value)} />
      </div>
      <div>
        <div className="customButton">
          <button type='submit'>
            <div>
              <SearchIcon />
            </div>
          </button>
        </div>
      </div>
    </form>
  )
}

export default SearchComponent