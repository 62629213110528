import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store';
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from './config/authConfig';

// Generate the msalInstance based on msalConfig
export const msalInstance = new PublicClientApplication(msalConfig);

const rootElement = document.getElementById('root') as HTMLElement;
const root = createRoot(rootElement);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App msalInstance={msalInstance}/>
    </Provider>
  </BrowserRouter>
);