import React, { useEffect, useState } from 'react'
import { config } from '../../../../config/clientConfig';
import { Department, ProjectDetails, projectUsers } from '../../../../store/actions/projectManagement/projectManagementType';
import { AddIcon, AdminIcon, DownArrowIcon, RemoveIcon } from '../../../reusableComponents/svgIcons';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/reducers/rootReducer';
import { UserDetailsType } from '../../../../store/actions/authentication/interfaces';
import { Autocomplete } from '@mui/material';

interface Props {
  index: number
  depData: Department
  projectData: ProjectDetails
  setProjectData: React.Dispatch<React.SetStateAction<ProjectDetails>>
  isEditable?: boolean
  usersList: UserDetailsType[]
  setUsersList: React.Dispatch<React.SetStateAction<UserDetailsType[]>>
  projectID?: string
}

interface userMapType {
  name: string;
  email: string;
}

const DepartmentView = ({ index, depData, projectData, setProjectData, isEditable, usersList, setUsersList, projectID }: Props) => {
  const isNewProjectActive = useSelector((state: RootState) => state.projectManagment.isNewProjectActive);
  /** This state for the According open and close action */
  const [isActive, setIsActive] = React.useState(config.negative);
  const [depList, setDepList] = useState<userMapType[]>([]);
  const [availableUsers, setAailableUsers] = useState<userMapType[]>([]);

  useEffect(() => {
    const temDepList: userMapType[] = [];
    const temAvaList: userMapType[] = [];
    // console.log(depData)
    // Below method to get list of users that they are already mapped in this project as a admin
    usersList.map((data) => {
      data.projects?.map((md) => {
        if (md.projectId === projectID && md.mappedDepts && md.mappedDepts.includes(depData.name)) {
          temDepList.push({ name: data.fisrtName, email: data.email })
        }
      })
    });
    // Below method to get list of users that they are not mapped in this project as a admin
    usersList.map((ulData) => {
      const hasName = temDepList.find((tdData) => tdData.email === ulData.email);
      !hasName && temAvaList.push({ name: ulData.fisrtName, email: ulData.email })
    })
    setAailableUsers(temAvaList);
    // temDepList.map((tdData) => tdData.email )
    setDepList(temDepList);
  }, [usersList, depData]);

  /** This function for set first accordion default open */
  useEffect(() => {
    if (index == 0) {
      setIsActive(config.positive);
    }
  }, [index]);

  /** This function for set first accordion default open */
  const handleIsActive = () => {
    setIsActive(!isActive);
  };

  const addUsersHandler = (newValue?: userMapType) => {
    if(newValue){
      const updatedData = usersList.map((ulData) => {
        if(ulData.email === newValue.email){
          const isExisit = ulData.projects?.find(ulData => ulData.projectId === projectID);
          return ({
            ...ulData,
            projects: isExisit && ulData.projects ?  ulData.projects.map(ulData => ulData.projectId === projectID ? ulData.mappedDepts ? ({...ulData, mappedDepts: [...ulData.mappedDepts, depData.name]}) : ({...ulData, mappedDepts: [depData.name]}) : ulData): ulData.projects ? [...ulData.projects, {projectId: projectID ?? "", role: "Participant", mappedDepts: [depData.name]}] : null,
            // projects: ulData.projects ? [...ulData.projects, {projectId: projectID ?? "", role: "", mappedDepts: []}] ulData.projects.map(ulData => ulData.projectId === projectID ? ulData.mappedDepts ? ({...ulData, mappedDepts: [...ulData.mappedDepts, depData.name]}) : ({...ulData, mappedDepts: [depData.name]}) : ulData): null,
            // mappedDepts: ulData.mappedDepts ? [...ulData.mappedDepts, {deptName: depData.name, projectId: projectID ?? ""}]
            //               : [{deptName: depData.name, projectId: projectID ?? ""}]
          })
        } else {
          return ulData
        }
      });
      setUsersList(updatedData);
    }
  }

  return (
    <div className={`PVDDetailsList ms-3 p-4 mt-2 ${isActive ? "active" : ""}`}>
      <div className='PVDChild'>
        <div className="d-flex justify-content-between align-items-center">
          <div className='pe-3'>
            <h5 className="subtitle-text">{!isEditable ? depData.name :
              <input
                type="text"
                placeholder='Type project name here'
                value={depData.name}
                disabled={!isNewProjectActive}
                onChange={(e) => setProjectData({ ...projectData, departments: projectData.departments.map((data, depIn) => index == depIn ? ({ ...data, name: e.target.value }) : data) })}
              />}
            </h5>
            <p className="p-text mt-1">{!isEditable ? depData.description :
              <input
                type="text"
                placeholder='Type project name here'
                value={depData.description}
                onChange={(e) => setProjectData({ ...projectData, departments: projectData.departments.map((data, depIn) => index == depIn ? ({ ...data, description: e.target.value }) : data) })}
              />}
            </p>
          </div>
          <div>
            <button className='expandButton' onClick={handleIsActive}><DownArrowIcon /></button>
          </div>
        </div>
        <div className="PVDUsers mt-3">
          <div className='PVUsers mt-3'>
            <div className="d-flex align-items-center ps-2">
              <AdminIcon /><div className='ps-2 PVUserTitle'>Users</div>
            </div>
            <div className="d-flex flex-wrap PVUsersRow mt-2">
              {depList.length > 0 ? depList.map((_dat, index) => (
                <div className='PVUsersChild ps-1 py-1 pe-5 mb-2 me-2' key={index}>
                  <div className="d-flex align-items-center">
                    <div><span>{index + 1}</span></div>
                    <div className='PVUserName ps-2'>{_dat.name}</div>
                    {isEditable && <button className='PVRemoveUser'><RemoveIcon /></button>}
                  </div>
                </div>
              )) : <div>No users mapped</div>}
              {isEditable && <button className='PVUsersChild addAdminButton ps-1 py-1 pe-4 mb-2 me-2'>
                <div className="d-flex align-items-center">
                  <div><span><AddIcon /></span></div>
                  <div className='PVUserName ps-2'>
                    <Autocomplete
                      sx={{
                        display: 'inline-block',
                        '& input': {
                          width: 200,
                          bgcolor: 'background.paper',
                          color: (theme) =>
                            theme.palette.getContrastText(theme.palette.background.paper),
                        },
                      }}
                      id="custom-input-demo"
                      disabled={depData.name === ""}
                      options={availableUsers ?? []}
                      getOptionLabel={(op) => op.email}
                      renderInput={(params) => (
                        <div ref={params.InputProps.ref}>
                          <input type="text" {...params.inputProps} placeholder='Add Admin' />
                        </div>
                      )}
                      onChange={(e, newValue) => addUsersHandler(newValue ?? undefined)}
                    />
                  </div>
                </div>
              </button>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DepartmentView