import React, { useEffect, useRef, useState } from 'react';
import $ from 'jquery';
import { AssessmentDetailsObjType, AssessmentModuleType } from '../../../../store/actions/assessmentManagement/assessmentManagementInterface';
import { getInvalidResponsesQuinicData } from '../../../../utils/filterUserResponse';

interface Props {
    assessmentData: AssessmentDetailsObjType
    setCurrentModule: React.Dispatch<React.SetStateAction<AssessmentModuleType>>
    setCurrentModuleIndex: React.Dispatch<React.SetStateAction<number>>
    currentModuleIndex: number
    setAssessmentData: React.Dispatch<React.SetStateAction<AssessmentDetailsObjType>>;
}

const ModulesSidebar = ({ assessmentData, setCurrentModule, setCurrentModuleIndex, currentModuleIndex, setAssessmentData }: Props) => {

    const moduleSelectionHandler = (data: any, index: number) => {

        setAssessmentData({...assessmentData, modules: getInvalidResponsesQuinicData(assessmentData).updatedStatus});
        // setCurrentModule(data);
        setCurrentModuleIndex(index);
    }

    window.onscroll = () => {
        window.scrollY > 94
            ? $(".modulesLeftNav").addClass("active")
            : $(".modulesLeftNav").removeClass("active");
    };

    return (
        <div className='modulesLeftNav px-3'>
            {
                assessmentData.modules?.map((data: AssessmentModuleType, index: number) => (
                    <button onClick={() => moduleSelectionHandler(data, index)} className={`modulesList py-3 d-flex align-items-center w-100 text-start ${index === currentModuleIndex ? "active" : ""}`} key={index}>
                        <div>
                            <div className='moduleNo'>{index > 9 ? index + 1 : "0" + (index + 1)}</div>
                        </div>
                        <div className='p-text ps-2 flex-grow-1'>{data.name}</div>
                        <div>
                            <div className={`moduleStatus ${data.status === "Completed" ? "active" : ""}`}>
                                <svg width="38" height="28" viewBox="0 0 38 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M37.117 1.21094C35.9413 0.0336457 34.0321 0.0343879 32.8548 1.21094L13.6719 20.3946L5.14592 11.8687C3.96863 10.6914 2.06026 10.6914 0.882969 11.8687C-0.294323 13.046 -0.294323 14.9544 0.882969 16.1316L11.54 26.7887C12.1283 27.3769 12.8996 27.6718 13.6711 27.6718C14.4425 27.6718 15.2147 27.3777 15.8029 26.7887L37.117 5.47382C38.2943 4.29734 38.2943 2.38816 37.117 1.21094Z" fill="black" />
                                </svg>
                            </div>
                        </div>
                    </button>
                ))
            }
        </div>
    )
}

export default ModulesSidebar