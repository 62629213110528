import { intersection } from 'lodash';
import Roles from '../config/Roles';

export function isLoggedIn() {
	/*
		* Note:
		*  This app assume if local storage have roles it means
		*  user is authenticated you can update this logic as per your app.
	*/
	return !!localStorage.getItem('role')
}

export function isArrayWithLength(arr: any) {
	return (Array.isArray(arr) && arr.length)
}

export function getAllowedRoutes(routes: any) {
	const roles = [localStorage.getItem('role')] ?? [Roles.PARTICIPANT];
	return routes.filter(({ permission }: any) => {
		if(!permission) return true;
		else if(!isArrayWithLength(permission)) return true;
		else return intersection(permission, roles).length;
	});
}
