import FetchData from '../components/FetchData';
import AssessmentManagement from '../components/pages/assessmentManagement/assessmentManagementIndex';
import MyAssessmentsIndex from '../components/pages/myAssessments/myAssessmentsIndex';
import ProjectManagement from '../components/pages/projectManagement/projectManagementIndex';
import UserManagement from '../components/pages/userManagement/userViewIndex';
import Roles from '../config/Roles';
import '../sass/style.css';

const AppRoutes = [
    {
        path: "/",
        element: <FetchData />
    },
    {
        path: "My-Assessments",
        element: <MyAssessmentsIndex />
    },
    {
        path: "Assessments-Management",
        element: <AssessmentManagement />,
        permission: [
            Roles.SUPER_ADMIN,
            Roles.ADMIN
        ]
    },
    {
        path: "Project-Management",
        element: <ProjectManagement />,
        permission: [
            Roles.SUPER_ADMIN,
            Roles.ADMIN
        ]
    },
    {
        path: "user-Management",
        element: <UserManagement />,
        permission: [
            Roles.SUPER_ADMIN,
            Roles.ADMIN
        ]
    },
    {
        path: 'fetch-data',
        element: <FetchData />
    }
];

export default AppRoutes;
