import { Autocomplete, Breadcrumbs } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { AddIcon, AdminIcon, CrownIcon, EditIcon, NextArrowIcon, RemoveIcon, SaveIcon } from '../../../reusableComponents/svgIcons'
import DepartmentView from './departmentView';
import { config } from '../../../../config/clientConfig';
import { ProjectDetails, projectUsers } from '../../../../store/actions/projectManagement/projectManagementType';
import { UserDetailsType } from '../../../../store/actions/authentication/interfaces';

interface Props {
  isEditable?: boolean,
  projectData: ProjectDetails
  setProjectData: React.Dispatch<React.SetStateAction<ProjectDetails>>
  setIsProjectViewActive: React.Dispatch<React.SetStateAction<boolean>>
  handleEditProject: () => void
  handleSaveProject: () => void
  handleBack: () => void;
  usersList: UserDetailsType[]
  setUsersList: React.Dispatch<React.SetStateAction<UserDetailsType[]>>
}

interface userMapType {
  name: string;
  email: string;
}

const ProjectView = ({ isEditable, projectData, setProjectData, setIsProjectViewActive, handleEditProject, handleSaveProject, handleBack, usersList, setUsersList }: Props) => {
  const [usersInList, setUsersInList] = useState<userMapType[]>([]);
  const [availableUsers, setAailableUsers] = useState<userMapType[]>([]);
  useEffect(() => {
    const temDepList: userMapType[] = [];
    const temAvaList: userMapType[] = [];
    // Below method to get list of users that they are already mapped in this project as a admin
    usersList.map((data) => {
      data.projects?.map((md) => {
        if (md.projectId === (projectData.projectId ?? "") && md.role === "ProjectAdmin") {
          temDepList.push({ name: data.fisrtName, email: data.email })
        }
      })
    });
    // Below method to get list of users that they are not mapped in this project as a admin
    usersList.map((ulData) => {
      const hasName = temDepList.find((tdData) => tdData.email === ulData.email);
      !hasName && temAvaList.push({ name: ulData.fisrtName, email: ulData.email })
    })
    // usersList.map((ulData) => {
    //   ulData.projects?.map(pId => pId.projectId).includes(projectData.projectId ?? "") ? ulData.projects?.map(pId => pId.projectId === (projectData.projectId ?? "") && pId.role === "ProjectAdmin" && temDepList.push({name: ulData.fisrtName, email: ulData.email}) ) : temAvaList.push({name: ulData.fisrtName, email: ulData.email})
    // });
    setAailableUsers(temAvaList);
    setUsersInList(temDepList);
  }, [projectData, usersList]);
  
  const addAdminHandler = (newValue?: userMapType) => {
    if(newValue){
      const updatedData = usersList.map((ulData) => {
        if(ulData.email === newValue.email){
          return ({
            ...ulData,
            projects: ulData.projects ? [...ulData.projects, ({projectId: projectData.projectId ?? "", role: "ProjectAdmin"})] : [({projectId: projectData.projectId ?? "", role: "ProjectAdmin"})]
          })
        } else {
          return ulData
        }
      });
      setUsersList(updatedData);
    }
  }
  const addNewDepartmentHandler = () => {
    setProjectData({...projectData, departments: [...projectData.departments, {name: "", description: ""}]})
  }
  return (
    <section className='projectViewSection px-3'>
      <div className='d-flex align-items-center mt-3 mb-4'>
        <div className='assessmentWPBreadcrumbs flex-grow-1'>
          <Breadcrumbs separator={<NextArrowIcon />} aria-label="breadcrumb">
            <button onClick={handleBack}>Project List</button>
            <div>{projectData.name === "" ? "Project Name" : projectData.name}</div>
          </Breadcrumbs>
        </div>
        <div className="customButton noBg">
          {!isEditable ? <button onClick={handleEditProject}><div><EditIcon /></div><div className='ps-3'>Edit</div></button>
            : <button onClick={handleSaveProject}><div><SaveIcon /></div><div className='ps-3'>Save</div></button>}
        </div>
      </div>
      <div className='PVDetails p-4'>
        <div className='d-flex flex-wrap flex-md-nowrap'>
          <div className='px-3'><div className='PVBox'>PN</div></div>
          <div className='flex-grow-1 PVDetailCon px-3'>
            <div>
              <h5 className='subtitle-text'>
                {!isEditable ? projectData.name :
                  <input
                    type="text"
                    placeholder='Type project name here'
                    value={projectData.name}
                    onChange={(e) => setProjectData({ ...projectData, name: e.target.value })}
                  />}
              </h5>
              <p className='p-text mt-1'>
                {!isEditable ? projectData.description :
                  <input
                    type="text"
                    placeholder='Type description here'
                    value={projectData.description}
                    className='w-100'
                    onChange={(e) => setProjectData({ ...projectData, description: e.target.value })}
                  />}
              </p>
              <div className='PVUsers mt-3'>
                <div className="d-flex align-items-center ps-2">
                  <AdminIcon /><div className='ps-2 PVUserTitle'>Admins</div>
                </div>
                <div className="d-flex flex-wrap PVUsersRow mt-2">
                  {usersInList.length > 0 ? usersInList.map((_dat, index) => (
                    <div className='PVUsersChild ps-1 py-1 mb-2 me-2 pe-5' key={index}>
                      <div className="d-flex align-items-center">
                        <div><span><CrownIcon />{index + 1}</span></div>
                        <div className='PVUserName ps-2'>{_dat.name}</div>
                        {isEditable && <button className='PVRemoveUser'><RemoveIcon /></button>}
                      </div>
                    </div>
                  )) : <div>No users mapped</div>}
                  {isEditable && <button className='PVUsersChild addAdminButton ps-1 py-1 pe-4 mb-2 me-2'>
                    <div className="d-flex align-items-center">
                      <div><span><AddIcon /></span></div>
                      <div className='PVUserName ps-2'>
                        <Autocomplete
                          sx={{
                            display: 'inline-block',
                            '& input': {
                              width: 200,
                              bgcolor: 'background.paper',
                              color: (theme) =>
                                theme.palette.getContrastText(theme.palette.background.paper),
                            },
                          }}
                          id="custom-input-demo"
                          options={availableUsers ?? []}
                          getOptionLabel={(op) => op.email}
                          renderInput={(params) => (
                            <div ref={params.InputProps.ref}>
                              <input type="text" {...params.inputProps} placeholder='Add Admin' />
                            </div>
                          )}
                          onChange={(e, newValue) => addAdminHandler(newValue ?? undefined)}
                        />
                      </div>
                      {/* <div className='PVUserName ps-2'>Add Admin</div> */}
                    </div>
                  </button>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='PVDDetails p-4 mt-3'>
        <div className="d-flex justify-content-between ps-4">
          <h1 className="subtitle-text">Departments</h1>
          {isEditable && <div className="customButton noBg">
            <button onClick={addNewDepartmentHandler}><div><AddIcon /></div><div className='ps-3'>Add Departments</div></button>
          </div>}
        </div>
        {projectData.departments && projectData.departments.map((data, index) => (
          <DepartmentView 
            index={index} 
            depData={data} 
            projectData={projectData} 
            setProjectData={setProjectData} 
            usersList={usersList}
            setUsersList={setUsersList}
            projectID={projectData.projectId} 
            isEditable={isEditable} 
            key={index} />
        ))}
      </div>
    </section>
  )
}

export default ProjectView