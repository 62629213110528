import React from 'react'
import { useDispatch } from 'react-redux';
/**Imports of SVG Icons */
import DevOpsImg from '../../assets/images/assessments/devSecOps-img-1.png';
import { AssessmentDetailsObjType } from '../../store/actions/assessmentManagement/assessmentManagementInterface';
import { storeEditableData } from '../../store/actions/myAssessments/myAssessmentsAction';
import { getStandardFormatDate } from '../../utils/getStandardFormatDate';
import { EditIcon } from './svgIcons';
import { AssessmentResponseWithAType } from '../../store/actions/assessmentManagement/assessmentResponseType';
import { data } from 'jquery';

interface Props {
    assessmentData: AssessmentDetailsObjType
    assessmenActiontHandler: (data: AssessmentResponseWithAType) => void
    aditionalData?: AssessmentResponseWithAType | undefined
    isViewFromManagement?: boolean
    isViewFromOwnAssessment?: boolean
    editableAssessmentHandler? : (aData: AssessmentDetailsObjType, type: string) => void;
}

const AssessmentListView = ({ 
        isViewFromManagement, 
        assessmentData, 
        assessmenActiontHandler, 
        aditionalData, 
        isViewFromOwnAssessment, 
        editableAssessmentHandler
    }: Props) => {
    // Published Date
    const poFormatedDate = assessmentData.publishedDate ? getStandardFormatDate(assessmentData.publishedDate) : null;
    // End Date
    const eFormatedDate = assessmentData.endDate ? getStandardFormatDate(assessmentData.endDate) : null;
    // Completed Date
    const cFormatedDate = aditionalData && aditionalData.completedOn ? getStandardFormatDate(aditionalData.completedOn) : null;

    return (
        <div className='myAssessmentsList'>
            <div className='d-flex listItem align-items-stretch justify-content-between mb-3'>
                <div>
                    <div className='AP_Img'><img src={DevOpsImg} alt='DevOpsImg' loading="lazy" /></div>
                </div>
                <div className='p-4 flex-grow-1'>
                    <div className='d-flex flex-wrap flex-md-nowrap align-items-stretch'>
                        <div className='myAssessmentLeftCon flex-grow-1'>
                            <h6 className='subtitle-text'>{assessmentData.assessmentType}</h6>
                            <h5 className='subtitle-text'>{assessmentData.title}</h5>
                            <div className='p-text mt-2'>{assessmentData.description}</div>
                            {/* <div className='p-text mt-2'>{assessmentData.modules?.map((moduleData: any) => moduleData.name === "Introduction" ? moduleData.description : "")}</div> */}
                            <div className='publishDetails mt-2 mt-md-4'>
                                <div className='p-text'>
                                    {aditionalData && <div>Status: <b className={`nt`}>{aditionalData?.status}</b></div>}
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='myAssessmentDates d-flex flex-column justify-content-between h-100 mt-3 mt-md-0'>
                                {assessmentData.isPublished && <><table>
                                    <tbody>
                                        {poFormatedDate && <tr className='poDate text-nowrap'>
                                            <td className='pr-2'>Published on</td>
                                            <td><b>: {poFormatedDate}</b></td>
                                        </tr>}
                                        {eFormatedDate && <tr className='poDate text-nowrap'>
                                            <td>End Date</td>
                                            <td><b>: {eFormatedDate}</b></td>
                                        </tr>}
                                        {cFormatedDate && <tr className='poDate text-nowrap'>
                                            <td><span className='pe-2 d-inline-block'>Completed Date</span></td>
                                            <td><b>: {poFormatedDate}</b></td>
                                        </tr>}
                                    </tbody>
                                </table></>}
                                {isViewFromOwnAssessment && aditionalData?.status !== "Completed" && <div className="customButton pe-5 mt-3 mt-md-0">
                                    <button onClick={() => assessmenActiontHandler(aditionalData ?? {} as AssessmentResponseWithAType)}><div>Start Assessment</div></button>
                                </div>}
                                {isViewFromManagement && assessmentData.isPublished && <div className="customButton pe-5 mt-3 mt-md-0">
                                    <button><div>View Report</div></button>
                                </div> }
                                {isViewFromOwnAssessment &&  aditionalData?.status === "Completed" && <div className="customButton pe-5 mt-3 mt-md-0">
                                    <button><div>View Report</div></button>
                                </div> }
                                {!assessmentData.isPublished && editableAssessmentHandler &&
                                    <><button className='editAssessmentAction ms-4' onClick={() => editableAssessmentHandler(assessmentData, "edit")}><EditIcon /></button><div className="customButton pe-5 mt-3">
                                        <button onClick={() => editableAssessmentHandler(assessmentData, "publish")}><div>Publish Now</div></button>
                                    </div></>}
                            </div>
                        </div>
                    </div>
                    {/* <div className='d-flex align-items-end justify-content-between'>
                    <div className='publishDetails'>
                      <div className='p-text mt-2'>
                        <div>Status: <b className={`nt`}>Not Started</b></div>
                      </div>
                    </div>
                    <div className="customButton pe-5">
                      <button onClick={() => startAssessmentHandler(data)}><div>Start Assessment</div></button>
                    </div>
                  </div> */}
                </div>
            </div>
        </div>
    )
}

export default AssessmentListView