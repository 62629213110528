// Sidebar Menu
export const MENU_OPEN = "MENU_OPEN";
export const MENU_CLOSE = "MENU_CLOSE";

// My Assessments
export const STORE_MY_ASSESSMENTS = "STORE_MY_ASSESSMENTS";
export const STORE_WRITING_ASSESSMENT = "STORE_WRITING_ASSESSMENT";

// Assessment Management
export const STORE_CREATED_ASSESSMENTS = "STORE_CREATED_ASSESSMENTS";
export const STORE_EDITABLE_ASSESSMENTS = "STORE_EDITABLE_ASSESSMENTS"

//Authentication Operation
export const SET_TOKEN = "SET_TOKEN";
export const SET_LOADING = "SET_LOADING";
export const STORE_USER_DETAILS = "STORE_USER_DETAILS";

// Project Management
export const STORE_VIEW_PROJECT = "STORE_VIEW_PROJECT";
export const REMOVE_VIEW_PROJECT = "REMOVE_VIEW_PROJECT";
export const NEW_PROJECT_ACTIVE = "NEW_PROJECT_ACTIVE";