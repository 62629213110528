import React, { Component, useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { IdTokenData } from './DataDisplay';

const FetchData = () => {
    const { accounts } = useMsal();
    if(accounts[0]?.idTokenClaims){
        return (
            <div>
               <IdTokenData idTokenClaims={accounts[0].idTokenClaims} />
            </div>
        );
    }else{
        return (
            <div>Not logged in</div>
        )
    }
}

export default FetchData;