import { AnyAction } from "redux";
import {
  NEW_PROJECT_ACTIVE,
  REMOVE_VIEW_PROJECT,
  STORE_VIEW_PROJECT,
} from "../../actions/actiontypes";
import { ProjectDetails } from "../../actions/projectManagement/projectManagementType";
import { config } from "../../../config/clientConfig";

interface InitStateType {
  projectViewDetails: ProjectDetails,
  isNewProjectActive: boolean
}

const initialState: InitStateType = {
  projectViewDetails: {} as ProjectDetails,
  isNewProjectActive: config.negative
};

// Get the username from action file and store in root state
export default function projectManagmentReducer(state = initialState, action: AnyAction): InitStateType {
  switch (action.type) {
    case STORE_VIEW_PROJECT:
      return {
        ...state,
        projectViewDetails: action.payload,
        isNewProjectActive: config.negative,
      };
    case REMOVE_VIEW_PROJECT:
      return {
        ...state,
        projectViewDetails: action.payload,
      };
    case NEW_PROJECT_ACTIVE:
      return {
        ...state,
        isNewProjectActive: action.payload,
      };
    default:
      return state;
  }
}
