/**Imports of React libraries */
import React, { useEffect, useState } from "react";

// Pro sidebar
import { MenuItem, SubMenu, Menu } from "react-pro-sidebar";

// Import navigation link
import { Link, useLocation } from "react-router-dom";
import { config } from "../../config/clientConfig";

// Import array for sidebar structure to implment in sidebar
import appMenuItems from "../../config/sidebarConfig";

// Import childer elements maping
import SidebarMenuChilds from "./sidebarMenuChilds";
import { getAllowedRoutes } from "../../utils/sidebar";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducers/rootReducer";

function SidebarMenuHead() {
  const [allowedRoutes, setAllowedRoutes] = useState([]);
  const userDetails = useSelector((state: RootState) => state.authenticationReducer.userDetails);
  useEffect(() => {
    const restrictedRoutes = getAllowedRoutes(appMenuItems);
    setAllowedRoutes(restrictedRoutes);
  }, [userDetails])
  const location = useLocation();
  return (
    <>
      {allowedRoutes.map(
        ({ path, title, children, Icon }: any, index: number) => {
          return (
            children ? (
                <Menu subMenuBullets={config.positive} key={index}>
                  <SubMenu
                    title={title}
                    icon={<Icon />}
                    className="firstLevelMenu"
                  >
                    <SidebarMenuChilds items={children} />
                  </SubMenu>
                </Menu>
              ) : (
                <Menu key={index}>
                  <MenuItem
                    active={location.pathname === path}
                    icon={<Icon />}>
                    {title}
                    {path && <Link to={path} />}
                  </MenuItem>
                </Menu>
              )
          );
        }
      )}
    </>
  );
}

export default SidebarMenuHead;
