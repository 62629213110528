import { AssessmentDetailsObjType, AssessmentModuleType, AssessmentQuestionOption } from "../store/actions/assessmentManagement/assessmentManagementInterface";
import { AssessmentResponseType } from "../store/actions/assessmentManagement/assessmentResponseType";

// filterResponses: This function takes an input array of type AssessmentModuleType and returns an array of filtered responses. To run this function, you can call filterResponses(inputArray) and pass in an array of AssessmentModuleType objects as the input.
function filterResponses(inputArray: AssessmentModuleType) {
  return inputArray.questions.filter((response) => {
    const multireponses = response.options;
    if (!multireponses) {
      return false;
    }
    const validMultireponses = multireponses.filter((mr) => mr.answer);
    if (validMultireponses.length) {
      response.options = validMultireponses;
      return true;
    }
    return false;
  });
}

// generateUserResponsedDetails: This function generates the user response details for an assessment based on the provided assessment data and user response data. To run this function, you can call generateUserResponsedDetails(assessmentData, userResponseData) and pass in the assessmentData object and userResponseData object as the arguments.
export const generateUserResponsedDetails = (assessmentData: AssessmentDetailsObjType, userResponseData: AssessmentResponseType) => {
  const resdata = assessmentData.modules.map((mData) => {
    if (mData.name === "Introduction") {
      return ({ name: "Introduction", status: "Completed" })
    } else {
      const filteredData = JSON.parse(JSON.stringify(mData));
      return ({
        name: mData.name,
        status: mData.status ? mData.status : "In Progress",
        reponses: filterResponses(filteredData).map((fData) => ({ name: fData.description, multireponses: fData.options?.map((fOpData) => ({ name: fOpData.name, answer: fOpData.answer })) }))
      })
    }
  });
  const [introData, ...restData] = resdata;
  const updatedModuleData = { ...userResponseData, modules: [introData, ...restData.filter((data) => data.reponses && data.reponses?.length > 0)] };
  return updatedModuleData;
}


// getInvalidResponses
export const getInvalidResponses = (obj: AssessmentModuleType) => {
  return obj.questions.reduce((invalidResponses, response) => {
    const invalidAnswers = response.options?.filter((multireponse: AssessmentQuestionOption) => !Object.prototype.hasOwnProperty.call(multireponse, 'answer') || multireponse.answer === "")
      .map((multireponse: AssessmentQuestionOption) => multireponse.name) || [];

    const missingMultireponses = response.options?.length === 0 ? ['multireponses'] : [];

    return invalidAnswers.length > 0 || missingMultireponses.length > 0
      ? { ...invalidResponses, [response.description]: [...invalidAnswers, ...missingMultireponses] }
      : invalidResponses;
  }, {});
}

export const getInvalidResponsesQuinicData = (assessmentData: AssessmentDetailsObjType) => {
  const resdata = assessmentData.modules.map((mData) => {
    const invalidResponses = getInvalidResponses(mData);
    if (Object.keys(invalidResponses).length !== 0) {
      return ({ moduleName: mData.name, ...invalidResponses })
    } else {
      return undefined
    }
  }).filter(el => el !== undefined);
  // console.log(resdata);

  const updatedStatus = assessmentData.modules.map((mData) => {
    if (resdata.some(obj => obj?.moduleName === mData.name)) {
      return ({
        ...mData,
        status: "In Progress"
      });
    } else {
      return ({
        ...mData,
        status: "Completed"
      })
    }
  });
  return ({resdata, updatedStatus});
}