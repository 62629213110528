import { STORE_EDITABLE_ASSESSMENTS, STORE_MY_ASSESSMENTS, STORE_WRITING_ASSESSMENT } from "../../actions/actiontypes";

const initialState = {
  assignedAssessments: [],
  writingAssessment: [],
  editableAssessment: {}
};

export default function myAssessmentsReducer(state = initialState, action: any) {
  switch (action.type) {
    case STORE_MY_ASSESSMENTS:
      return {
        ...state,
        assignedAssessments: action.payload,
      };
    case STORE_WRITING_ASSESSMENT:
      return {
        ...state,
        writingAssessment: action.payload,
      };
    case STORE_EDITABLE_ASSESSMENTS:
      return {
        ...state,
        editableAssessment: action.payload
      };
    default:
      return state;
  }
}
