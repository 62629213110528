/**Imports of React libraries */
import React, { useEffect, useState } from 'react';
/** Import Of reusable components */
import SearchComponent from '../../reusableComponents/searchComponent';
/**Import of Config files */
import { getCreatedAssessments } from '../../../store/actions/assessmentManagement/assessmentManagementAction';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers/rootReducer';
import Loader from '../../loader/loader';
import AssessmentListView from '../../reusableComponents/assessmentListView';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { AssessmentDetailsObjType, AssessmentDetailsType } from '../../../store/actions/assessmentManagement/assessmentManagementInterface';
import EmptyScreenVector from "../../../assets/images/avatars/assessmentEmptySvg.svg";
import AssessmentCreation from './assessmentCreation/assessmentCreationIndex';
import { AddIcon, SaveIcon } from '../../reusableComponents/svgIcons';
import { publishAssessment, storeEditableData } from '../../../store/actions/myAssessments/myAssessmentsAction';
import { config } from '../../../config/clientConfig';

const AssessmentManagement = () => {
  const dispatch = useDispatch();
  const [key, setKey] = useState('Published');
  const [isLoading, setIsDataLoading] = useState<boolean>(config.positive);
  const [isAssessmentCreationActive, setIsAssessmentCreationActive] = useState<boolean>(config.negative);
  const userDetails = useSelector((state: RootState) => state.authenticationReducer.userDetails);
  const [selectedAssessment, setSelectedAssessment] = useState<any>({});
  const publishedAssessments: AssessmentDetailsType = useSelector(
    (state: RootState) => state.manageAssessments.publishedAssessments
  );
  const draftedAssessments: AssessmentDetailsType = useSelector(
    (state: RootState) => state.manageAssessments.draftedAssessments
  );
  const initialGet = async () => {
    console.log("first")
    setIsDataLoading(config.positive);
    await dispatch(getCreatedAssessments());
    setIsDataLoading(config.negative);
  }

  useEffect(() => {
    let ignore = config.negative;
    !ignore && initialGet();
    return () => { ignore = config.positive }
  }, [userDetails])

  const startAssessmentHandler = () => { console.log("first") };

  const searchHandler = (value: string) => {
    console.log(value);
  }

  const editableAssessmentHandler = async (assessmentData: AssessmentDetailsObjType, type: string) => {
    if (type === "edit") {
      await dispatch(storeEditableData(assessmentData));
      setIsAssessmentCreationActive(config.positive);
      initialGet();
    } else if (type === "publish") {
      await dispatch(publishAssessment(assessmentData));
      initialGet();
    }
  }

  if (!isAssessmentCreationActive) {
    return (
      <section className='assessmentManagementSection'>
        {isLoading ? <Loader /> :
          <div>
            <div className='d-flex justify-content-between align-items-center'>
              <div>
                <div className='assessmentTopSticky d-flex flex-wrap justify-content-between align-items-center px-3 pt-4 pb-2'>
                  <SearchComponent searchHandler={searchHandler} />
                </div>
              </div>
              <div className="customButton noBg saveButton">
                <button onClick={() => setIsAssessmentCreationActive(config.positive)}><div><AddIcon /></div><div className='ps-3'>Create Assessment</div></button>
              </div>
            </div>
            {publishedAssessments.length > 0 || draftedAssessments.length > 0 ? (
              <div className='px-3 pt-2'>
                <div className='navTabs'>
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k ? k : "")}
                    className="mb-3"
                  >
                    <Tab eventKey="Published" title="Published">
                      {publishedAssessments.length > 0 ? publishedAssessments.map((data: AssessmentDetailsObjType, index: number) => (
                        <AssessmentListView
                          isViewFromManagement={config.positive}
                          assessmentData={data}
                          assessmenActiontHandler={startAssessmentHandler}
                          key={index} />
                      )) : "There is no published assessments!!"}
                    </Tab>
                    <Tab eventKey="Drafted" title="Drafted">
                      {draftedAssessments.length > 0 ? draftedAssessments.map((data: AssessmentDetailsObjType, index: number) => (
                        <AssessmentListView
                          isViewFromManagement={config.positive}
                          assessmentData={data}
                          assessmenActiontHandler={startAssessmentHandler}
                          editableAssessmentHandler={editableAssessmentHandler}
                          key={index} />
                      )) : "There is no drafted assessments!!"}
                    </Tab>
                  </Tabs>
                </div>
              </div>
            ) : (
              <div className='emptyAssessmentScreen px-3 pt-2 pb-5'>
                <div>
                  <img src={EmptyScreenVector} alt="EmptyScreenVector" />
                  <p className='p-text text-center'>Assessment is empty, <br />
                    <span>Please click on below button to create assessment</span></p>
                  <div className="customButton mt-3">
                    <button onClick={() => setIsAssessmentCreationActive(config.positive)}><div>Create Assessment</div></button>
                  </div>
                </div>
              </div>
            )}

          </div>
        }
      </section >
    )
  } else {
    return (
      <>
        <div className='px-3 pt-4'>
          <AssessmentCreation setIsAssessmentCreationActive={setIsAssessmentCreationActive} />
        </div>
      </>
    );
  }
};

export default AssessmentManagement