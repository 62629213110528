import { AxiosError } from "axios";
import { Dispatch } from "redux";
import axiosInstance from "../../../utils/leoAxios";
import { NEW_PROJECT_ACTIVE, REMOVE_VIEW_PROJECT, STORE_VIEW_PROJECT } from "../actiontypes";
import { ProjectDetails } from "./projectManagementType";
import { config } from "../../../config/clientConfig";


/** Get List form assigned Assessments */
export const storeProjectViewDetails = (pID: string) => async (dispatch: Dispatch) => {
    // const accessToken
    const response = await axiosInstance({
        method: "GET",
        url: "/api/Project/" + pID
    }).then((res) => {
        dispatch({
            payload: res.data[0] || [],
            type: STORE_VIEW_PROJECT,
        });
        return res.data;
    }).catch((err: AxiosError) => err.response?.data);
}

/** Get List form assigned Assessments */
export const storeNewProjectViewDetails = (pDetails: ProjectDetails) => async (dispatch: Dispatch) => {
    await dispatch({
        payload: pDetails,
        type: STORE_VIEW_PROJECT,
    });
    await dispatch({
        payload: config.positive,
        type: NEW_PROJECT_ACTIVE,
    });
}

/** Get List form assigned Assessments */
export const editProjectDetails = (pDetails: ProjectDetails) => async (dispatch: Dispatch) => {
    const {users, ...restData} = pDetails;
    const response = await axiosInstance({
        method: "PUT",
        url: "/api/Project/" + pDetails.projectId,
        data: JSON.stringify(restData)
    }).then((res) => {
        dispatch({
            payload: pDetails || [],
            type: STORE_VIEW_PROJECT,
        });
        return res.data;
    }).catch((err: AxiosError) => err.response?.data);
}

/** Get List form assigned Assessments */
export const saveNewProject = (pDetails: ProjectDetails): any => async (dispatch: Dispatch) => {
    const {users, ...restData} = pDetails;
    const response = await axiosInstance({
        method: "POST",
        url: "/api/Project",
        data: JSON.stringify(restData)
    }).then((res) => {
        dispatch({
            payload: pDetails || [],
            type: STORE_VIEW_PROJECT,
        });
        console.log(res.data)
        return res.data;
    }).catch((err: AxiosError) => err.response?.data);
    return response;
}

/** Get List form assigned Assessments */
export const removeProjectViewDetails = () => async (dispatch: Dispatch) => {
    dispatch({
        payload: {},
        type: REMOVE_VIEW_PROJECT,
    });
}
  