import React, { useState } from 'react'
import { config } from '../../../../config/clientConfig';
import { ProjectDetails } from '../../../../store/actions/projectManagement/projectManagementType';
import SearchComponent from '../../../reusableComponents/searchComponent'
import { AddIcon, DeleteIcon, EditIcon, NextArrowIcon } from '../../../reusableComponents/svgIcons';

interface Props {
  projectDetails: ProjectDetails[]
  handleViewIndividualProject: (pData: ProjectDetails, type: string) => void
  setIsProjectViewActive: React.Dispatch<React.SetStateAction<boolean>>
}

const ProjectList = ({ projectDetails, handleViewIndividualProject, setIsProjectViewActive }: Props) => {
  const [searchedAssessment, setSearchedAssessment] = useState([] as any[]);

  const searchHandler = (value: string) => {
    const searchedData: any[] = search("", value);
    setSearchedAssessment(searchedData);
  }

  /* here we create a function 
  //     we filter the items
  // use array property .some() to return an item even if other requirements didn't match
  */
  const search = (items: any, q: string) => {
    const searchParam = ["title", "categoryName"];
    const searchedData: any[] = [];
    items.map((data: any) => {
      const matchedData = data.assessment.filter((item: any) => {
        return searchParam.some((newItem) => {
          return (
            item[newItem]
              .toString()
              .toLowerCase()
              .indexOf(q.toLowerCase()) > -1
          );
        });
      });
      matchedData.length > 0 && searchedData.push(data);
    })
    return searchedData;
  }

  const newProjectHandler = () => {
    const newProjectData: ProjectDetails = {
      projectId: "",
      name: "",
      description: "",
      departments: [{name: "", description: ""}]
    }
    handleViewIndividualProject(newProjectData, "NEW")
  }
  return (
    <section className='projectListSection pb-4'>
      <div className='assessmentTopSticky d-flex flex-wrap justify-content-between align-items-center px-3 pt-4 pb-2'>
        <SearchComponent searchHandler={searchHandler} />
        <div className="customButton noBg saveButton">
          <button onClick={newProjectHandler}><div><AddIcon /></div><div className='ps-3'>Create Project</div></button>
        </div>
      </div>
      <div className="projectList px-3 pt-2">
        {projectDetails.map((projectData, index) => (
          <div className='projectListChild p-4 mt-3' key={index}>
            <div className='d-flex w-100'>
              <div><div className='PLBox'>PN</div></div>
              <div className='PLDetails flex-grow-1 px-3'>
                <h4 className='subtitle-text'>{projectData.name}</h4>
                <p className='p-text'>{projectData.description}</p>
              </div>
              <div className='PLActions align-self-center'>
                <div className="d-flex">
                  <div className='pe-4'><button onClick={() => handleViewIndividualProject(projectData, "EDIT_PROJECT")}><EditIcon /></button></div>
                  <div className='ps-4 lineLeft'>
                    <button className='disabled' disabled><DeleteIcon /></button>
                  </div>
                </div>
              </div>
              <div className='PLGo align-self-center ps-5 pe-4'>
                <button onClick={() => handleViewIndividualProject(projectData, "EXISTING")}><NextArrowIcon /></button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default ProjectList