import { AxiosError } from "axios";
import { config } from "../../../config/clientConfig";
import axiosInstance from "../../../utils/leoAxios";
import {
  SET_LOADING,
  SET_TOKEN,
  STORE_USER_DETAILS,
} from "../actiontypes";
import { IdTokenClaims } from "./interfaces";

export const validateUserByToken = (token: string, jsObj: IdTokenClaims) => async (dispatch: any) => {
  localStorage.setItem('token', token);
  localStorage.setItem('userEmail', jsObj.emails[0]);
  localStorage.setItem('userName', jsObj.name);
  localStorage.setItem('tokenexpiry', jsObj.exp.toString());
  const userName = localStorage.getItem('userEmail');
  const response = await axiosInstance({
    method: "GET",
    url: "/api/User/" + userName + "/getUserInfo"
  }).then((res) => {
    localStorage.setItem('role', res.data[0].role)
    dispatch({
      payload: { token, jsObj, userDetails: res.data[0] },
      type: SET_TOKEN,
    });
    return res.data;
  }).catch((err: AxiosError) => err.response?.data);
};

export const getUserDetails = () => async (dispatch: any) => {
  const userName = localStorage.getItem('userEmail');
  const response = await axiosInstance({
    method: "GET",
    url: "/api/User/" + userName + "/getUserInfo"
  }).then((res) => {
    dispatch({
      payload: res.data[0] ?? [],
      type: STORE_USER_DETAILS,
    });
    return res.data;
  }).catch((err: AxiosError) => err.response?.data);
};

/**Set Current Loading Screen */
export const inmemoryLoading = (IsLoading: any) => (dispatch: any) => {
  dispatch({
    payload: IsLoading,
    type: SET_LOADING,
  });
};

// Validate user token expiry value from msalinstance
export const validateTokenExpiry = (tokenExpiry: number): boolean => {
  // The conversion to * 1000 is required as token expiry value is default to 10 digits
  // Inorder to compare with current date time, we need 13 digits
  const userTokenExpiryTime = tokenExpiry * 1000;
  const currentLocalTime = new Date().getTime();
  return currentLocalTime > userTokenExpiryTime ? config.positive : config.negative;
};